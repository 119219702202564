<template>
  <en-drawer :model-value="modelValue" title="发放优惠券" direction="btt" @close="$emit('update:model-value', false)" size="60%">
    <en-form label-position="left">
      <en-form-item :label="`本次发送${type === 'customer' ? '客户数' : '车辆数'}${normalizedOptions.length}`">
        <en-tag v-for="item of normalizedOptions">{{ item.name }}</en-tag>
      </en-form-item>
    </en-form>

    <div class="pb-5">
      <en-button type="primary" @click="opetation.add.click">添加卡券</en-button>
    </div>

    <en-table :height="300" :data="table.data">
      <en-table-column label="操作">
        <template #default="{ $index }">
          <en-button type="primary" link @click="table.delete.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="卡券名称" width="200">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <select-maintain
            v-model="row.mallCoupon"
            :ajax="{
              action: 'GET /enocloud/mall/coupon',
              params: (params, value) => (params.payload = { name: value, statusCode: 'A' })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>
      </en-table-column>
      <en-table-column label="卡券类型"></en-table-column>
      <en-table-column label="面值">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">{{ formatMoney(row.mallCoupon?.faceValue) }}</template>
      </en-table-column>
      <en-table-column label="满减金额">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ formatMoney(row.mallCoupon?.lowerLimitAmount) }}</template
        >
      </en-table-column>
      <en-table-column label="折扣">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'P' ? row.mallCoupon?.discountValue : '--' }}
        </template>
      </en-table-column>
      <en-table-column label="抵扣金额">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'F' ? formatMoney(row.mallCoupon?.discountValue) : '--' }}
        </template>
      </en-table-column>
      <en-table-column label="项目" prop="mallCoupon.maintenance.name"></en-table-column>
      <en-table-column label="配件" prop="mallCoupon.goods.name"></en-table-column>
      <en-table-column label="有效期类型" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <select-maintain
            v-model="row.expireType"
            :ajax="{
              action: 'GET /enocloud/common/lookup/:lookupType',
              params: (params, value) => (params.paths = ['EXPTYPE'])
            }"
            :props="{ label: 'description', value: '' }"
            value-key="code"
            class="w-full"
          ></select-maintain>
        </template>
      </en-table-column>
      <en-table-column label="有效期" width="200">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-date-picker v-if="row.expireType?.code === 'F'" v-model:start="row.startDate" v-model:end="row.endDate" class="w-full"></en-date-picker>
          <en-input-number v-if="row.expireType?.code === 'D'" v-model="row.validityDays" :min="0" :precision="0"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="单次领取量">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input-number v-model="row.count" @input="table.count.change(row, $event)" :precision="0" class="w-full" :min="1"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="固定核销天数" prop="count" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input-number v-model="row.periodDays" :disabled="row.count && row.count > 1" :min="0" :precision="0" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="固定核销次数" prop="count" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input-number
            v-model="row.countPerInstance"
            :disabled="row.count && row.count > 1"
            :min="1"
            :precision="0"
            class="w-full"
          ></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="卡券备注" prop="comment" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          <en-input v-model="row.comment"></en-input>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { get } from 'lodash-es'
import { EnMessage } from '@enocloud/components'

interface Props {
  name?: string
  id?: string
}

export default factory({
  props: {
    modelValue: Boolean,
    options: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    props: Object as PropType<Props>,
    type: String as PropType<'customer' | 'vehicle'>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler() {}
    }
  },

  computed: {
    normalizedProps() {
      return Object.assign({ name: 'name', id: 'id' }, this.props) as Required<Props>
    },
    normalizedOptions() {
      return (this.options ?? []).map((option) => {
        return {
          id: get(option, this.normalizedProps.id),
          name: get(option, this.normalizedProps.name)
        }
      })
    }
  },

  config: {
    children: {
      opetation: {
        add: {
          click() {
            this.table.data.push({
              expireType: { code: 'D', message: '相对时间', description: '领取立即生效', type: '' },
              validityDays: 30,
              count: 1
            })
          }
        }
      },
      table: {
        data: [] as EnocloudMallDefinitions['MallComboCouponDto'][],
        ajax: {
          submit: {
            action: 'POST /enocloud/mall/coupon/instance/customer/send',
            params(params) {
              params.payload = { customerIds: [], coupons: this.table.data, vehicleIds: [] }

              if (this.type === 'vehicle') {
                params.payload.vehicleIds = this.normalizedOptions.map((item) => item.id) ?? []
              } else {
                params.payload.customerIds = this.normalizedOptions.map((item) => item.id) ?? []
              }
            }
          }
        },
        children: {
          delete: {
            click(index: number) {
              this.table.data.splice(index, 1)
            }
          },
          count: {
            change(row: EnocloudMallDefinitions['MallComboCouponDto'], value: number) {
              if (value > 1) {
                row.periodDays = 0
                row.countPerInstance = 1
              }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            if (!this.normalizedOptions.length) {
              EnMessage.warning(`请至少选择${this.type === 'customer' ? '一位客户' : '一辆车'}`)
              return
            }
            if (!this.table.data.length) {
              EnMessage.warning('请至少添加一种卡券')
              return
            }
            await this.table.submit()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
