<template>
  <div v-loading="loading" ref="panelRef" class="select-none"></div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from 'vue'
import { intersection, indexOf, difference } from 'lodash-es'

interface Props {
  name: string
  codes?: string[]
}

interface Emits {
  (e: 'update:codes', value: string[]): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const fillColor = '#e8e8e8'
const primaryColor = '#F4523B'

const loading = ref(false)
const panelRef = ref<HTMLDivElement | null>()

const localCodes = computed<string[]>(() => props.codes ?? [])

const root_of_car_codes = ['RWWS', 'RWNS', 'RWHS']
const domin = 'https://enocherp-oss01.oss-cn-hangzhou.aliyuncs.com'
const dir = 'surfaces'
const source = computed(() => `${domin}/${dir}/${props.name}.svg`)

const travseElement = (element: Element, callback: (element: Element) => any, { passparent } = { passparent: false }) => {
  const stack = [element]
  while (stack.length) {
    const current = stack.pop()
    current?.hasAttribute('data-code') && callback(current)
    passparent && current?.parentElement?.hasAttribute('data-code') && callback(current)
    if (current?.children) {
      for (let i = current.children.length - 1; i >= 0; i--) {
        stack.push(current.children[i])
      }
    }
  }
}

const createSvgElement = async (name: string) => {
  loading.value = true
  try {
    const res = await fetch(source.value).then((res) => res.text())
    const domParser = new DOMParser()
    const xmlDoc = domParser.parseFromString(res, 'text/xml')
    if (!xmlDoc) {
      throw Error(`No svg path element in your svg file.\nPath : ${source.value}`)
    }
    xmlDoc.documentElement.setAttribute('width', `auto`)
    xmlDoc.documentElement.setAttribute('height', `auto`)
    panelRef.value && (panelRef.value.innerHTML = xmlDoc.documentElement.outerHTML)
    travseElement(panelRef.value!, (element) => element.addEventListener('click', onElementClick))
    updateSvgElement()
  } catch (e) {
    console.error(`${name}.svg is not found`, e)
  } finally {
    loading.value = false
  }
}

const onElementClick = (e: Event) => {
  const element = e.target as Element
  const datacode = element.getAttribute('data-code') || element.parentElement?.getAttribute('data-code') || ''
  const datacodes = datacode?.split(',')
  if (datacodes?.length) {
    const _codes = [...localCodes.value]

    if (
      !difference(
        datacodes,
        root_of_car_codes.map((code) => props.name.split('-').join('_') + '_' + code)
      ).length
    ) {
      if (element.getAttribute('fill') === primaryColor) {
        for (const code of root_of_car_codes.map((code) => props.name.split('-').join('_') + '_' + code)) {
          if (indexOf(_codes, code) > -1) _codes.splice(indexOf(_codes, code), 1)
        }
      } else {
        _codes.push(props.name.split('-').join('_') + '_' + 'RWNS')
      }
    } else {
      for (const code of datacodes) {
        const index = _codes.findIndex((item) => item === code)
        if (index > -1) _codes.splice(index, 1)
        else _codes.push(code)
      }
    }

    emits('update:codes', _codes)
  }
}

const updateSvgElement = async () => {
  await nextTick()
  travseElement(
    panelRef.value!,
    (element) => {
      const datacode = element.getAttribute('data-code') || element.parentElement?.getAttribute('data-code') || ''
      const datacodes = datacode?.split(',')
      const message = element.getAttribute('data-message') || element.parentElement?.getAttribute('data-message') || ''
      if (!message) return
      element.setAttribute('fill', intersection(datacodes, localCodes.value).length ? primaryColor : fillColor)
    },
    { passparent: true }
  )
}

watch(source, createSvgElement, { immediate: true })
watch(localCodes, updateSvgElement)
</script>
