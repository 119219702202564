<template>
  <div class="w-full flex justify-center">
    <div
      ref="contentRef"
      :style="{
        'grid-template-columns': `repeat(${config.columns}, 1fr)`,
        'grid-template-rows': `repeat(${grids.length / config.columns}, minmax(${config.rowHeight}px, auto))`
      }"
      class="select-none relative w-180 grid border-l-1 border-t-1 border-dashed border-gray-200 text-xs"
    >
      <div
        v-for="(grid, index) of grids"
        :class="['flex justify-center items-center cursor-pointer']"
        :style="{
          'align-items': getCell(index)?.data.alignItems,
          'font-size': getCell(index)?.data.fontSize + 'px',
          'font-weight': getCell(index)?.data.bold ? 'bold' : 'normal',
          'z-index': getCell(index) &&  getCell(index)?.columnSpan! > 1 ? 1 : 0,
          'justify-content': getCell(index)?.data.justifyContent,
          'border-top': getCell(index)?.data.topBorder && '1px solid #606266',
          'border-right': getCell(index)?.data.rightBorder ? '1px solid #606266' : '1px dashed #CDD0D6',
          'border-bottom': getCell(index)?.data.bottomBorder ? '1px solid #606266' : '1px dashed #CDD0D6',
          'border-left': getCell(index)?.data.leftBorder && '1px solid #606266',
          ...gridArea(index)
        }"
      >
        <span>{{ getCell(index)?.data.prefix }}</span>
        <span>{{ getCell(index)?.data.value || getCell(index)?.data.type?.sampleValue }}</span>
        <span>{{ getCell(index)?.data.suffix }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watchEffect, toRaw, type CSSProperties } from 'vue'
import { assign, find, findIndex, filter, remove, maxBy, forEach, debounce, max } from 'lodash-es'
import { ArrowRight as IArrowRight } from '@element-plus/icons-vue'
import { ajax } from '@enocloud/utils'

type ReportTemplateFieldDto = EnocloudCommonDefinitions['ReportTemplateFieldDto']

interface Props {
  templateType: string
  data: ReportTemplateFieldDto[]
}

const props = withDefaults(defineProps<Props>(), { data: () => [] })

const config = {
  columns: 48,
  rows: 20,
  rowHeight: 30,
  width: 720
}

const calColumnIndexByIndex = (index: number) => Math.floor(index % config.columns)
const calRowIndexByIndex = (index: number) => Math.floor(index / config.columns)

class Cell implements Pick<ReportTemplateFieldDto, 'rowIndex' | 'rowSpan' | 'columnIndex' | 'columnSpan'> {
  rowIndex: number = 0
  rowSpan: number = 1
  columnIndex: number = 0
  columnSpan: number = 1

  gridIndex: number = 0

  constructor(public data: ReportTemplateFieldDto) {
    this.data = toRaw(data)
    this.rowIndex = this.data.rowIndex!
    this.rowSpan = this.data.rowSpan!
    this.columnIndex = this.data.columnIndex!
    this.columnSpan = this.data.columnSpan!

    this.gridIndex = this.rowIndex * config.columns + this.columnIndex
  }
}

const grids = computed(() => {
  const maxRowIndex = maxBy(props.data, 'rowIndex')?.rowIndex ?? 0
  return Array(config.columns * (maxRowIndex >= 20 ? maxRowIndex : 20)).fill(undefined)
})

const cells = computed(() =>
  props.data.map((item) => {
    return new Cell(item)
  })
)

const getCell = (index: number) => {
  return cells.value.find((item) => item.gridIndex === index)
}

const gridArea = (index: number): CSSProperties => {
  const cell = getCell(index)

  let gridRowStart = 0,
    gridRowEnd = 0,
    gridColumnStart = 0,
    gridColumnEnd = 0

  if (cell) {
    gridRowStart = cell.rowIndex + 1
    gridRowEnd = gridRowStart + cell.rowSpan
    gridColumnStart = cell.columnIndex + 1
    gridColumnEnd = gridColumnStart + cell.columnSpan
  } else {
    gridRowStart = calRowIndexByIndex(index) + 1
    gridRowEnd = gridRowStart + 1
    gridColumnStart = calColumnIndexByIndex(index) + 1
    gridColumnEnd = gridColumnStart + 1
  }

  if (index < 10) {
    console.log('我是' + index, cell ? '找到啦' : '没找到', gridRowStart, gridRowEnd, gridColumnStart, gridColumnEnd)
  }

  return {
    'grid-row-start': gridRowStart,
    'grid-row-end': gridRowEnd,
    'grid-column-start': gridColumnStart,
    'grid-column-end': gridColumnEnd
  }
}


</script>
