<template>
  <en-form v-if="form.ready" :model="modelValue" :rules="rules" :loading="loading || !form.ready" :ref="setRef('form')">
    <en-form-item label="车牌" prop="plateNo">
      <select-maintain
        :model-value="modelValue?.plateNo"
        :ajax="{
          action: 'GET /enocloud/common/vehicle',
          params: (params, value) => (params.payload = { quickSearch: value })
        }"
        :props="{ label: 'plateNo', value: 'plateNo' }"
        remote
        allow-create
        class="w-full"
        @change="form.change('plateNo', $event)"
      >
      </select-maintain>
    </en-form-item>
    <en-form-item label="VIN码" prop="vin">
      <en-input :model-value="modelValue?.vin" @change="form.change('vin', $event)" @blur="form.vin.blur"></en-input>
    </en-form-item>
    <en-form-item label="车品牌" prop="vehicleSpec">
      <cascader-maintain
        :model-value="modelValue?.vehicleSpec"
        :options="form.vehicleSpec.data"
        :props="{ label: 'name', value: 'name', children: 'models' }"
        allow-create
        class="w-full"
        @change="form.change('vehicleSpec', $event)"
      ></cascader-maintain>
    </en-form-item>
    <en-form-item label="车品牌代码">
      <en-input :model-value="modelValue?.modelCode" @change="form.change('modelCode', $event)"></en-input>
    </en-form-item>
    <en-form-item label="车品牌类型" prop="type.id">
      <select-maintain
        :model-value="modelValue?.type"
        :ajax="{ action: 'GET /enocloud/common/vehicle/type', params: (params) => (params.payload = { pagingEnabled: false }) }"
        :props="{ label: 'name', value: '' }"
        value-key="id"
        class="w-full"
        @change="form.change('type', $event)"
      >
      </select-maintain>
    </en-form-item>
    <en-form-item label="发动机">
      <div class="grid grid-cols-[1fr_1fr_2fr] gap-2">
        <en-input :model-value="modelValue?.engineModel" placeholder="型号" @change="form.change('engineModel', $event)"></en-input>
        <en-input :model-value="modelValue?.outputVolume" placeholder="排量" @change="form.change('outputVolume', $event)"></en-input>
        <select-maintain
          :model-value="modelValue?.turboCharged"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['FLAG']) }"
          :props="{ label: (option: EnocloudCommonDefinitions['LookupDto']) => (option.code === 'Y' ? 'T' : '无'), value: '' }"
          value-key="code"
          class="w-full"
          @change="form.change('turboCharged', $event)"
        ></select-maintain>
      </div>
    </en-form-item>
    <en-form-item label="发动机号" prop="engineNumber">
      <en-input :model-value="modelValue?.engineNumber" @change="form.change('engineNumber', $event)"></en-input>
    </en-form-item>
    <en-form-item label="变速箱">
      <select-maintain
        :model-value="modelValue?.gearboxType"
        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['GRBOXTYPE']) }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('gearboxType', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="前轮胎型号" prop="frontTireModel">
      <en-input :model-value="modelValue?.frontTireModel" @change="form.change('frontTireModel', $event)"></en-input>
    </en-form-item>
    <en-form-item label="后轮胎型号" prop="rearTireModel">
      <en-input :model-value="modelValue?.rearTireModel" @change="form.change('rearTireModel', $event)"></en-input>
    </en-form-item>
    <en-form-item label="购车日期" prop="purchasingDate">
      <en-date-picker :model-value="modelValue?.purchasingDate" class="w-full" @change="form.change('purchasingDate', $event)"></en-date-picker>
    </en-form-item>
    <en-form-item label="色号" prop="colorCode">
      <en-input :model-value="modelValue?.colorCode" @change="form.change('colorCode', $event)"></en-input>
    </en-form-item>
    <en-form-item label="外观颜色" prop="color">
      <en-input :model-value="modelValue?.color" @change="form.change('color', $event)"></en-input>
    </en-form-item>
    <en-form-item label="司机">
      <en-input :model-value="modelValue?.driver" @change="form.change('driver', $event)"></en-input>
    </en-form-item>
    <en-form-item label="司机手机号">
      <en-input :model-value="modelValue?.driverCellphone" @change="form.change('driverCellphone', $event)"></en-input>
    </en-form-item>
    <en-form-item label="当前公里数">
      <en-input :model-value="modelValue?.currentMiles" @change="form.change('currentMiles', $event)"></en-input>
    </en-form-item>
    <en-form-item label="保养周期">
      <en-input-number
        :model-value="modelValue?.maintenanceCycle"
        :min="0"
        @change="form.change('maintenanceCycle', $event)"
        class="w-full"
      ></en-input-number>
    </en-form-item>
    <en-form-item label="下次保养里程">
      <en-input-number
        :model-value="modelValue?.nextMaintenanceMileage"
        :min="0"
        @change="form.change('nextMaintenanceMileage', $event)"
        class="w-full"
      ></en-input-number>
    </en-form-item>
    <en-form-item label="下次保养日期">
      <en-date-picker
        :model-value="modelValue?.nextMaintenanceDate"
        class="w-full"
        @change="form.change('nextMaintenanceDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="上次保养里程">
      <en-input :model-value="modelValue?.lastMiles" @change="form.change('lastMiles', $event)" disabled></en-input>
    </en-form-item>
    <en-form-item label="上次保养日期">
      <en-date-picker
        :model-value="modelValue?.lastMaintenanceDate"
        class="w-full"
        @change="form.change('lastMaintenanceDate', $event)"
        disabled
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="交险投保公司" prop="compulsoryInsuranceCompany.id">
      <select-maintain
        :model-value="modelValue?.compulsoryInsuranceCompany"
        :ajax="{
          action: 'GET /enocloud/common/customer',
          params: (params, value) => (params.payload = { name: value, type: 'I' })
        }"
        :props="{ label: 'name', value: '' }"
        value-key="id"
        remote
        class="w-full"
        @change="form.change('compulsoryInsuranceCompany', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="交险投保日期（起）" prop="compulsoryInsuranceStartDate">
      <en-date-picker
        :model-value="modelValue?.compulsoryInsuranceStartDate"
        class="w-full"
        @change="form.change('compulsoryInsuranceStartDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="交险投保日期（止）" prop="compulsoryInsuranceEndDate">
      <en-date-picker
        :model-value="modelValue?.compulsoryInsuranceEndDate"
        class="w-full"
        @change="form.change('compulsoryInsuranceEndDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="商险投保公司" prop="commercialInsuranceCompany.id">
      <select-maintain
        :model-value="modelValue?.commercialInsuranceCompany"
        :ajax="{
          action: 'GET /enocloud/common/customer',
          params: (params, value) => (params.payload = { name: value, type: 'I' })
        }"
        :props="{ label: 'name', value: '' }"
        value-key="id"
        remote
        class="w-full"
        @change="form.change('commercialInsuranceCompany', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="商险投保日期（起）" prop="commercialInsuranceStartDate">
      <en-date-picker
        :model-value="modelValue?.commercialInsuranceStartDate"
        class="w-full"
        @change="form.change('commercialInsuranceStartDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="商险投保日期（止）" prop="commercialInsuranceEndDate">
      <en-date-picker
        :model-value="modelValue?.commercialInsuranceEndDate"
        class="w-full"
        @change="form.change('commercialInsuranceEndDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="车辆年检日期" prop="annualInspectionDate">
      <en-date-picker
        :model-value="modelValue?.annualInspectionDate"
        class="w-full"
        @change="form.change('annualInspectionDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="驾驶证年检日">
      <en-date-picker
        :model-value="modelValue?.driverLicenceDueDate"
        class="w-full"
        @change="form.change('driverLicenceDueDate', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="燃料类型">
      <select-maintain
        :model-value="modelValue?.fuelCategory"
        :ajax="{
          action: 'GET /enocloud/common/lookup/:lookupType',
          params: (params) => (params.paths = ['FLCTGY'])
        }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('fuelCategory', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="车辆使用性质">
      <select-maintain
        :model-value="modelValue?.useCategory"
        :ajax="{
          action: 'GET /enocloud/common/lookup/:lookupType',
          params: (params) => (params.paths = ['VHUSECTGY'])
        }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('useCategory', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="车辆登记日期">
      <en-date-picker :model-value="modelValue?.recordDate" class="w-full" @change="form.change('recordDate', $event)"></en-date-picker>
    </en-form-item>
    <en-form-item label="车辆备注">
      <en-input :model-value="modelValue?.comment" @change="form.change('comment', $event)"></en-input>
    </en-form-item>
    <en-form-item label="行驶证正面" class="col-start-1">
      <upload-maintain
        :model-value="modelValue?.driverLicenceFirstUrls"
        dir="driverLicenceFirst"
        multiple
        :limit="1"
        @change="form.change('driverLicenceFirstUrls', $event)"
      ></upload-maintain>
    </en-form-item>
    <en-form-item label="行驶证反面">
      <upload-maintain
        :model-value="modelValue?.driverLicenceSecondUrls"
        dir="driverLicenceSecondUrl"
        multiple
        :limit="1"
        @change="form.change('driverLicenceSecondUrls', $event)"
      ></upload-maintain>
    </en-form-item>
    <en-form-item label="行驶证附页">
      <upload-maintain
        :model-value="modelValue?.driverLicenceThirdUrls"
        dir="driverLicenceThirdUrl"
        multiple
        :limit="1"
        @change="form.change('driverLicenceThirdUrls', $event)"
      ></upload-maintain>
    </en-form-item>
    <en-form-item label="铭牌">
      <upload-maintain
        :model-value="modelValue?.nameplateUrls"
        multiple
        dir="nameplateUrl"
        @change="form.change('nameplateUrls', $event)"
      ></upload-maintain>
    </en-form-item>
    <en-form-item label="车身照片">
      <upload-maintain :model-value="modelValue?.imgUrls" multiple dir="imgUrl" @change="form.change('imgUrls', $event)"></upload-maintain>
    </en-form-item>
  </en-form>
</template>

<script lang="ts">
type VehicleDto = EnocloudCommonDefinitions['VehicleDto']

const vinMap = new Map<string, string[]>()

export default factory({
  props: {
    modelValue: Object as PropType<VehicleDto>,
    loading: Boolean as PropType<boolean>
  },

  emits: {
    change: (value: VehicleDto) => typeof value === 'object'
  },

  data() {
    return {
      rules: {} as Record<string, any>
    }
  },

  // LE4FTM8K25A883416

  config: {
    children: {
      form: {
        ready: false,
        rules: {},
        change<P extends keyof VehicleDto>(prop: P, value: any) {
          const emitValue = Object.assign({}, this.modelValue)
          emitValue[prop] = value
          this.emit('change', emitValue)
        },
        children: {
          vehicleSpec: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/brand',
                data: 'array',
                params(params) {
                  params.payload = { depth: 2 }
                }
              }
            }
          },
          vin: {
            async blur() {
              const vinCode = this.modelValue?.vin
              if (!vinCode) return
              let vehicleSpec = vinMap.get(vinCode)
              if (!vehicleSpec) {
                const data = this.form.vehicleSpec.data
                const res = await this.ajax('GET /enocloud/common/vehicle/spec/:vinCode', { paths: [vinCode] })
                const { subBrand = '', vehicleSys = '' } = res.data[0]
                const current = data.find((item) => item.name === subBrand)
                if (current) {
                  const subCurrent = current.models.find((item) => item.name === vehicleSys)
                  if (subCurrent) {
                    vehicleSpec = [current.name!, subCurrent.name!]
                  } else {
                    current.models.push({ name: vehicleSys })
                    vehicleSpec = [current.name!, vehicleSys]
                  }
                } else {
                  data.push({ name: subBrand, models: [{ name: vehicleSys }] })
                  vehicleSpec = [subBrand, vehicleSys]
                }
                vinMap.set(vinCode, vehicleSpec)
              }

              const emitValue = Object.assign({}, this.modelValue)
              emitValue.vehicleSpec = vehicleSpec
              this.emit('change', emitValue)
            }
          }
        }
      }
    }
  },

  async mounted() {
    const fields = (await this.ajax('GET /enocloud/form/fields/setting', { payload: { formFieldsType: 'SV' } })).data

    for (const field of fields) {
      if (field.required?.value) {
        const [, prop] = field.serviceFormFieldsType?.prop.split('.')
        switch (prop) {
          case 'vin':
            this.rules['vin'] = [
              { required: true, message: '请输入vin码' },
              { min: 17, max: 17, message: '请输入17位vin码', trigger: 'blur' }
            ]
            break
          case 'type':
          case 'compulsoryInsuranceCompany':
          case 'commercialInsuranceCompany':
            this.rules[`${prop}.id`] = { required: true, message: `请选择${field.serviceFormFieldsType?.message}` }
            break
          case 'compulsoryInsuranceEndDate':
            this.rules['compulsoryInsuranceStartDate'] = { required: true, message: '请选择交险投保日期（起）' }
            this.rules['compulsoryInsuranceEndDate'] = { required: true, message: '请选择交险投保日期（止）' }
            break
          case 'commercialInsuranceEndDate':
            this.rules['commercialInsuranceStartDate'] = { required: true, message: '请选择商险投保日期（起）' }
            this.rules['commercialInsuranceEndDate'] = { required: true, message: '请选择商险投保日期（止）' }
            break
          default:
            this.rules[prop] = { required: true, message: `请填写${field.serviceFormFieldsType?.message}` }
        }
      }
    }

    this.form.ready = true

    await this.form.vehicleSpec.get()
  },

  methods: {
    validate() {
      return this.refs.form?.validate()
    },
    clearValidate() {
      return this.refs.form?.clearValidate()
    }
  }
})
</script>
