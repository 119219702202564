<template>
  <en-drawer title="客户档案" direction="btt" size="80%" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="tabs.active">
          <en-tab-pane label="车辆信息" name="vehicle">
            <en-scrollbar :height="height - 55">
              <vehicle-form
                :model-value="vehicle.data"
                :loading="vehicle.loading"
                :ref="setRef('vehicleForm')"
                @change="vehicle.change"
                class="grid grid-cols-4 gap-x-6"
              ></vehicle-form>
            </en-scrollbar>
          </en-tab-pane>
          <en-tab-pane label="车主基本信息" name="customer">
            <en-scrollbar :height="height - 55">
              <customer-form
                :model-value="customer.data"
                :loading="customer.loading"
                :ref="setRef('customerForm')"
                @change="customer.change"
                class="grid grid-cols-4 gap-x-6"
              ></customer-form>
            </en-scrollbar>
          </en-tab-pane>
        </en-tabs>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import CustomerForm from '@business/components/customer-form.vue'
import VehicleForm from '@business/components/vehicle-form.vue'

export default factory({
  components: { CustomerForm, VehicleForm },

  props: {
    modelValue: Boolean,
    customerData: Object as PropType<{ id?: number }>,
    vehicleData: Object as PropType<{ id?: number }>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.customerData?.id) {
            this.customer.data.id = this.customerData.id
            this.customer.get()
          }

          if (this.vehicleData?.id) {
            this.vehicle.data.id = this.vehicleData.id
            this.vehicle.get()
          }
        } else {
          this.vehicle.init()
          this.customer.init()
        }
      }
    }
  },

  config: {
    children: {
      tabs: {
        active: 'vehicle'
      },
      customer: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/:customerId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.customer.data.id]
            }
          },
          update: {
            action: 'PUT /enocloud/common/customer',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.customer.data
            }
          }
        },
        change(value: EnocloudCommonDefinitions['CustomerDto']) {
          Object.assign(this.customer.data, value)
        }
      },
      vehicle: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.vehicle.data.id]
            }
          },
          update: {
            action: 'PUT /enocloud/common/vehicle',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.vehicle.data
            }
          }
        },
        change(value: EnocloudCommonDefinitions['VehicleDto']) {
          Object.assign(this.vehicle.data, value)
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.refs.vehicleForm.refs.form.validate()
            await this.refs.customerForm.refs.form.validate()
            await this.vehicle.update()
            await this.customer.update()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
