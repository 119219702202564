<template>
  <en-form :model="modelValue" :rules="rules" :loading="loading" :ref="setRef('form')">
    <en-form-item label="客户编号">
      <en-input :model-value="modelValue?.serialNo" disabled></en-input>
    </en-form-item>
    <en-form-item label="客户助记符">
      <en-input :model-value="modelValue?.nameHint" disabled></en-input>
    </en-form-item>
    <en-form-item label="客户名称" prop="name">
      <select-maintain
        :model-value="modelValue?.name"
        :ajax="{
          action: 'GET /enocloud/common/customer',
          params: (params, value) => (params.payload = { quickSearch: value })
        }"
        :props="{ label: 'name', value: 'name' }"
        remote
        allow-create
        class="w-full"
        @change="form.change('name', $event)"
      >
      </select-maintain>
    </en-form-item>
    <en-form-item label="联系人手机" prop="cellphone">
      <en-input :model-value="modelValue?.cellphone" @change="form.change('cellphone', $event)"></en-input>
    </en-form-item>
    <!-- <en-form-item label="客户类型" prop="type.code">
      <select-maintain
        :model-value="modelValue?.type"
        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CUSTTYPE']) }"
        :props="{
          label: 'message',
          value: '',
          disabled: (option: EnocloudCommonDefinitions['LookupDto']) => {
            return Boolean(option.code === 'B' && !store.accessRightsHash.CUSTOMER_SUPPLIER_QUERY)
          }
        }"
        value-key="code"
        class="w-full"
      ></select-maintain>
    </en-form-item> -->
    <en-form-item label="客户分类" prop="category.id">
      <select-maintain
        :model-value="modelValue?.category"
        :ajax="{
          action: 'GET /enocloud/common/customer/category',
          params: (params, value) => (params.payload = { name: value })
        }"
        :props="{ label: 'name', value: '' }"
        value-key="id"
        remote
        class="w-full"
        @change="form.change('category', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="客户来源" prop="salesmanType.code">
      <select-maintain
        :model-value="modelValue?.salesmanType"
        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CLKTYPE']) }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('salesmanType', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="客户介绍人" prop="salesman.name">
      <en-input v-if="modelValue?.salesmanType?.code === 'N'" :model-value="modelValue?.salesman?.name" disabled></en-input>
      <select-maintain
        v-else-if="modelValue?.salesmanType?.code === 'O'"
        :model-value="modelValue?.salesman?.name"
        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SALSMAN']) }"
        :props="{ label: 'name', value: 'name' }"
        allow-create
        class="w-full"
        @change="form.change('salesman', $event)"
      ></select-maintain>
      <select-maintain
        v-else-if="modelValue?.salesmanType?.code === 'I'"
        :model-value="modelValue?.salesman?.name"
        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['CUSISNSSMAN']) }"
        :props="{ label: 'name', value: 'name' }"
        allow-create
        class="w-full"
        @change="form.change('salesman', $event)"
      ></select-maintain>
      <select-maintain
        v-else
        :ajax="{
          action: 'GET /enocloud/common/clerk',
          params: (params, value) => (params.payload = { code: modelValue?.salesmanType?.code, name: value })
        }"
        :props="{ label: 'name', value: '' }"
        :model-value="modelValue?.salesman"
        value-key="id"
        remote
        class="w-full"
        @change="form.change('salesman', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="联系人生日" prop="dob">
      <en-date-picker :model-value="modelValue?.dob" class="w-full" @change="form.change('dob', $event)"></en-date-picker>
    </en-form-item>
    <en-form-item label="所在地区">
      <cascader-area :model-value="modelValue?.area" class="w-full" @change="form.change('area', $event)"></cascader-area>
    </en-form-item>
    <en-form-item label="客户联系人" prop="contactName">
      <en-input :model-value="modelValue?.contactName" @change="form.change('contactName', $event)"></en-input>
    </en-form-item>
    <en-form-item label="联系人性别">
      <select-maintain
        :model-value="modelValue?.gender"
        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['GENDER']) }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('gender', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="服务管家" prop="serviceAdvisor.id">
      <select-maintain
        :model-value="modelValue?.serviceAdvisor"
        :ajax="{ action: 'GET /enocloud/common/advisorteam/advisor', params: (params, value) => (params.payload = { name: value }) }"
        :props="{ label: 'user.name', value: 'user' }"
        value-key="id"
        class="w-full"
        @change="form.change('serviceAdvisor', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="最大信用额度">
      <en-input-number :model-value="modelValue?.creditLine" :min="0" class="w-full" @change="form.change('creditLine', $event)"></en-input-number>
    </en-form-item>
    <en-form-item label="会员卡号" prop="membershipCardNo">
      <en-input :model-value="modelValue?.membershipCardNo" @change="form.change('membershipCardNo', $event)"></en-input>
    </en-form-item>
    <en-form-item label="客户状态">
      <select-maintain
        :model-value="modelValue?.status"
        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CUSTSTAT']) }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('status', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="客户年龄">
      <en-input :model-value="modelValue?.age" @change="form.change('age', $event)"></en-input>
    </en-form-item>
    <en-form-item label="工时折扣率">
      <template #label>
        <div class="flex justify-between">
          <span>工时折扣率</span>
          <span>{{ formatPercent(modelValue?.serviceMaintenanceDiscountRate) }}</span>
        </div>
      </template>
      <en-input-number
        :max="1"
        :min="0"
        :step="0.1"
        :precision="4"
        :model-value="modelValue?.serviceMaintenanceDiscountRate"
        class="w-full"
        @change="form.change('serviceMaintenanceDiscountRate', $event)"
      ></en-input-number>
    </en-form-item>
    <en-form-item label="配件折扣率">
      <template #label>
        <div class="flex justify-between">
          <span>配件折扣率</span>
          <span>{{ formatPercent(modelValue?.serviceGoodsDiscountRate) }}</span>
        </div>
      </template>
      <en-input-number
        :max="1"
        :min="0"
        :step="0.1"
        :precision="4"
        :model-value="modelValue?.serviceGoodsDiscountRate"
        class="w-full"
        @change="form.change('serviceGoodsDiscountRate', $event)"
      ></en-input-number>
    </en-form-item>
    <en-form-item label="座机">
      <en-input :model-value="modelValue?.telephone" @change="form.change('telephone', $event)"></en-input>
    </en-form-item>
    <en-form-item label="传真">
      <en-input :model-value="modelValue?.fax" @change="form.change('fax', $event)"></en-input>
    </en-form-item>
    <en-form-item label="邮箱">
      <en-input :model-value="modelValue?.email" @change="form.change('email', $event)"></en-input>
    </en-form-item>
    <en-form-item label="驾照类型">
      <select-maintain
        :model-value="modelValue?.driverLicenceType"
        :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['DVLCTYPE']) }"
        :props="{ label: 'message', value: '' }"
        value-key="code"
        class="w-full"
        @change="form.change('driverLicenceType', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="驾照有效期">
      <en-date-picker
        :end="modelValue?.driverLicenceAvailableTo"
        :start="modelValue?.driverLicenceAvailableFrom"
        type="daterange"
        class="w-full"
        @change="form.change('driverLicenceAvailable', $event)"
      ></en-date-picker>
    </en-form-item>
    <en-form-item label="初次领证">
      <en-date-picker :model-value="modelValue?.dateOfLicensing" class="w-full" @change="form.change('dateOfLicensing', $event)"></en-date-picker>
    </en-form-item>
    <en-form-item label="开户银行">
      <select-maintain
        :model-value="modelValue?.depositBank"
        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['BANK']) }"
        :props="{ label: 'name', value: 'name' }"
        allow-create
        class="w-full"
        @change="form.change('depositBank', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="开户账号">
      <en-input :model-value="modelValue?.depositAccount" @change="form.change('depositAccount', $event)"></en-input>
    </en-form-item>
    <en-form-item label="税号">
      <en-input :model-value="modelValue?.taxNo" @change="form.change('taxNo', $event)"></en-input>
    </en-form-item>
    <en-form-item label="发票类型">
      <select-maintain
        :model-value="modelValue?.invoiceTypeName"
        :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['INVCTP']) }"
        :props="{ label: 'name', value: 'name' }"
        class="w-full"
        @change="form.change('invoiceTypeName', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="法人">
      <en-input :model-value="modelValue?.legalRepresentative" @change="form.change('legalRepresentative', $event)"></en-input>
    </en-form-item>
    <en-form-item label="经营范围">
      <en-input :model-value="modelValue?.scope" @change="form.change('scope', $event)"></en-input>
    </en-form-item>
    <en-form-item label="客户标签" class="col-start-1 col-span-2">
      <select-maintain
        :model-value="modelValue?.tags"
        :ajax="{ action: 'GET /enocloud/common/customer/tag' }"
        multiple
        allow-create
        collapseTags
        collapseTagsPanel
        class="w-full"
        @change="form.change('tags', $event)"
      ></select-maintain>
    </en-form-item>
    <en-form-item label="详细地址" prop="address" class="col-span-2">
      <en-input :model-value="modelValue?.address" type="textarea" @change="form.change('address', $event)"></en-input>
    </en-form-item>
    <en-form-item label="备注" class="col-span-2">
      <en-input :model-value="modelValue?.comment" type="textarea" @change="form.change('comment', $event)"></en-input>
    </en-form-item>
    <en-form-item label="驾驶证" class="col-start-1">
      <upload-maintain
        :model-value="modelValue?.driverLicenceUrls"
        multiple
        dir="nameplateUrl"
        :limit="1"
        @change="form.change('driverLicenceUrls', $event)"
      ></upload-maintain>
    </en-form-item>
    <en-form-item label="身份证反面">
      <upload-maintain
        :model-value="modelValue?.identityCardBackUrl"
        dir="identityCardBackUrls"
        :limit="1"
        @change="form.change('identityCardBackUrl', $event)"
      ></upload-maintain>
    </en-form-item>
    <en-form-item label="身份证正面">
      <upload-maintain
        :model-value="modelValue?.identityCardFrontUrl"
        dir="identityCardFrontUrls"
        :limit="1"
        @change="form.change('identityCardFrontUrl', $event)"
      ></upload-maintain>
    </en-form-item>
  </en-form>
</template>

<script lang="ts">
type CustomerDto = EnocloudCommonDefinitions['CustomerDto']

export default factory({
  props: {
    modelValue: {
      type: Object as PropType<CustomerDto>,
      require: true
    },
    loading: Boolean as PropType<boolean>
  },

  emits: {
    change: (value: CustomerDto) => typeof value === 'object'
  },

  data() {
    return {
      rules: {} as Record<string, any>
    }
  },

  config: {
    children: {
      form: {
        change(prop: string, value: any) {
          const emitValue: Record<string, any> = Object.assign({}, this.modelValue)

          switch (prop) {
            case 'driverLicenceAvailable':
              emitValue.driverLicenceAvailableFrom = value[0]
              emitValue.driverLicenceAvailableTo = value[1]
              break
            default:
              emitValue[prop] = value
          }
          this.emit('change', emitValue as CustomerDto)
        }
      }
    }
  },

  async mounted() {
    const fields = (await this.ajax('GET /enocloud/form/fields/setting', { payload: { formFieldsType: 'SC' } })).data

    for (const field of fields) {
      if (field.required?.value) {
        const [, prop] = field.serviceFormFieldsType?.prop.split('.')
        switch (prop) {
          case 'salesmanType':
            this.rules['salesmanType.code'] = { required: true, message: '请选择客户来源' }
            break
          case 'category':
            this.rules[`${prop}.id`] = { required: true, message: `请选择${field.serviceFormFieldsType?.message}` }
            break
          case 'salesman':
            this.rules['salesman.name'] = { required: true, message: '请填写客户介绍人' }
            break
          case 'serviceAdvisor':
            this.rules['serviceAdvisor.id'] = { required: true, message: '请选择服务管家' }
            break
          default:
            this.rules[prop] = { required: true, message: `请填写${field.serviceFormFieldsType?.message}` }
        }
      }
    }
  }
})
</script>
