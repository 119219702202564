<template>
  <div v-loading="loading" class="w-full">
    <div
      class="select-none grid border border-solid border-gray-200"
      :style="{
        'grid-template-columns': `repeat(${config.columns}, 1fr)`,
        'grid-template-rows': `repeat(${normalizedFields.length / config.columns}, minmax(${config.rowHeight}px, auto))`
      }"
    >
      <div v-for="item of normalizedFields" class="flex" :style="item.style">
        <template v-if="item.type === 'INDEX'">
          <span>{{ item.value }}</span>
        </template>

        <template v-else-if="item.type === 'IMAGE'">
          <en-image v-if="item.value" :src="item.value"></en-image>
        </template>

        <template v-else-if="item.type === 'TEXT'">
          <span>{{ item.prefix }}</span>
          <span>{{ item.value }}</span>
          <span>{{ item.suffix }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { assign, get, find, findIndex, maxBy, cloneDeep } from 'lodash-es'
import { computed, watchEffect, ref, type CSSProperties } from 'vue'
import { ajax } from '@enocloud/utils'

type TemplateFieldDto = EnocloudCommonDefinitions['TemplateFieldDto']
type ReportTemplateRowConfigDto = EnocloudCommonDefinitions['ReportTemplateRowConfigDto']
type ReportTemplateFieldDto = Omit<EnocloudCommonDefinitions['ReportTemplateFieldDto'], 'items' | 'values'> & {
  parent?: ReportTemplateFieldDto
  items: ReportTemplateFieldDto[]
  values: string[][]
}

interface Config {
  columns: number
  width: number
  rowHeight: number
  maxRowIndex: number
  borderColor: string
}

interface Props {
  type?: string
  config?: ReportTemplateRowConfigDto[]
  fields?: ReportTemplateFieldDto[]
  disabled?: boolean
  loading?: boolean
  props?: { value: string }
}

type FieldType = 'INDEX' | 'TEXT' | 'IMAGE'

interface Emits {
  (e: 'update:fields', value: ReportTemplateFieldDto[]): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const config = computed<Config>(() => ({ columns: 48, width: 720, rowHeight: 30, maxRowIndex: 20, borderColor: '#f0f0f0' }))
const normalizedProps = computed(() => assign({ value: 'value' }, props.props))

const options = ref<TemplateFieldDto[]>([])

class Fields {
  style: CSSProperties = {
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14
  }
  prefix = ''
  value = ''
  suffix = ''
  rowIndex = 1
  columnIndex = 1
  type: FieldType = 'TEXT'

  parent: ReportTemplateFieldDto | null = null

  constructor(index: number, fields: ReportTemplateFieldDto[], _props: Props['props'], config: Config, options: TemplateFieldDto[]) {
    this.rowIndex = Math.floor(index / config.columns)
    this.columnIndex = Math.floor(index % config.columns)

    const field = find(fields, { rowIndex: this.rowIndex, columnIndex: this.columnIndex })

    if (field) {
      const {
        alignItems,
        leftBorder,
        rightBorder,
        bottomBorder,
        topBorder,
        justifyContent,
        rowIndex = 0,
        rowSpan = 1,
        columnIndex = 0,
        columnSpan = 1,
        type,
        prefix,
        suffix,
        fontSize,
        bold,
        parent
      } = field

      this.style.alignItems = alignItems
      this.style.justifyContent = justifyContent
      this.style.gridArea = `${rowIndex + 1} / ${columnIndex + 1} / ${rowIndex + 1 + rowSpan} / ${columnIndex + 1 + columnSpan}`
      this.style.fontSize = fontSize + 'px'

      if (bold) this.style.fontWeight = 'bold'

      if (leftBorder) this.style.borderLeft = `1px solid ${config.borderColor}`
      if (rightBorder) this.style.borderRight = `1px solid ${config.borderColor}`
      if (bottomBorder) this.style.borderBottom = `1px solid ${config.borderColor}`
      if (topBorder) this.style.borderTop = `1px solid ${config.borderColor}`

      this.type = (type?.fieldType as FieldType) ?? 'TEXT'
      this.prefix = prefix
      this.suffix = suffix

      if (parent) {
        this.parent = parent
        const index = findIndex(parent.items, ['columnIndex', columnIndex])
        const itemIndex = rowIndex! - parent.rowIndex!
        this.value = parent.values[itemIndex][index] ?? ''
      } else {
        this.value = get(field, _props?.value!)
      }
    }
  }
}

const flatFields = computed(() => {
  return (props.fields ?? []).reduce((fields, field) => {
    const { type, rowIndex = 0, values, items } = field

    switch (type?.fieldType) {
      case 'TABLE':
        for (const [index, _] of values.entries()) {
          for (const item of cloneDeep(items)) {
            item.rowIndex = rowIndex + index
            item.parent = cloneDeep(field)
            fields.push(item)
          }
        }
        break
      default:
        fields.push(field)
    }
    return fields
  }, [] as ReportTemplateFieldDto[])
})

const maxRowIndex = computed(() => maxBy(flatFields.value, 'rowIndex')?.rowIndex ?? config.value.maxRowIndex)

const normalizedFields = computed(() => {
  return Array(config.value.columns * maxRowIndex.value)
    .fill(undefined)
    .map((_, index) => {
      return new Fields(index, flatFields.value, normalizedProps.value, config.value, options.value)
    })
})

// const getTypeOptions = async (type: string) => {
//   try {
//     const res = await ajax('GET /enocloud/common/report/template/type/:code', { paths: [type] })
//     options.value = res.data
//   } catch (err) {}
// }

// watch(toRef(props, 'type'), getTypeOptions, { immediate: true })
</script>
