<template>
  <en-drawer :model-value="visible" @close="$emit('update:visible', false)" title="新增配件" size="800">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-2 gap-x-6">
      <en-form-item label="配件名称" prop="name">
        <en-input v-model="form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="配件类别" prop="category.id">
        <select-maintain
          v-model="form.data.category"
          :ajax="{
            action: 'GET /enocloud/common/goods/category',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="基本单位" prop="warehouseUnit">
        <select-maintain
          v-model="form.data.warehouseUnit"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['GDSUNT'])
          }"
          :props="{ label: 'name', value: 'name' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="品质类型" prop="type.code">
        <select-maintain
          v-model="form.data.type"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['GOODSTP'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="OE号" prop="oem">
        <en-input v-model="form.data.oem"></en-input>
      </en-form-item>
      <en-form-item label="编码">
        <en-input :model-value="form.data.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="固定维修价格">
        <en-input-number v-model="form.data.servicePrice" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="配置统一价格">
        <select-maintain
          v-model="form.data.uniqueServicePrice"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FLAG'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="安全库存下限" prop="upperBoundary">
        <en-input v-model="form.data.upperBoundary"></en-input>
      </en-form-item>
      <en-form-item label="安全库存上限" prop="lowerBoundary">
        <en-input v-model="form.data.lowerBoundary"></en-input>
      </en-form-item>
      <en-form-item label="配件品牌">
        <select-maintain
          v-model="form.data.brand"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['GDBRD'])
          }"
          :props="{ label: 'name', value: 'name' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="配件型号">
        <en-input v-model="form.data.model"></en-input>
      </en-form-item>
      <en-form-item label="配件条码">
        <select-maintain
          v-model="form.data.barcodes"
          :options="[]"
          multiple
          default-first-option
          allow-create
          clearable
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="产地">
        <en-input v-model="form.data.placeOfProduction"></en-input>
      </en-form-item>
      <en-form-item label="配件状态">
        <select-maintain
          v-model="form.data.status"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['GODSTAT'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" maxlength="200" type="textarea" show-word-limit></en-input>
      </en-form-item>
      <en-form-item label="配件图片" class="col-span-2">
        <upload-maintain v-model="form.data.imgUrls" multiple :limit="10" folder="goods"></upload-maintain>
      </en-form-item>
      <!-- <en-form-item label="采购数量">
        <en-input></en-input>
      </en-form-item>
      <en-form-item label="采购单价">
        <en-input></en-input>
      </en-form-item>
      <en-form-item label="维修价格">
        <en-input></en-input>
      </en-form-item> -->
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
      <en-button type="primary" @click="footer.confirmAdd.click">确定并继续</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const formDataInit = (props: Partial<EnocloudCommonDefinitions['GoodsDto']> = {}): EnocloudCommonDefinitions['GoodsDto'] => {
  const data = {} as EnocloudCommonDefinitions['GoodsDto']
  return Object.assign(data, props)
}

export default factory({
  props: {
    visible: Boolean as PropType<boolean>
  },

  watch: {
    visible: {
      handler(value) {
        if (!value) {
          this.form.init()
        }
      }
    }
  },

  emits: {
    'update:visible': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        rules: {
          name: { required: true, message: '请输入配件名称' },
          'category.id': { required: true, message: '请选择配件类别' },
          'type.code': { required: true, message: '请选择品质类型' },
          oem: { required: true, message: '请输入oem' },
          warehouseUnit: { required: true, message: '请选择基本单位' }
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/common/goods',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:visible', false)
            this.form.data
          }
        },
        confirm: {
          async click() {
            await this.form.submit()
            this.emit('update:visible', false)
            this.emit('confirm')
          }
        },
        confirmAdd: {
          async click() {
            await this.form.submit()
            this.form.init()
          }
        }
      }
    }
  }
})
</script>
