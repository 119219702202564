<template>
  <en-form :model="model" :rules="fields.normalizedRules" :loading="loading || fields.loading" :ref="setRef('form')">
    <en-form-item v-for="item of fields.visibleFields" :label="item.serviceFormFieldsType?.message" :prop="item.serviceFormFieldsType?.prop">
      <slot :name="item.serviceFormFieldsType!.code!"></slot>
    </en-form-item>
    <slot></slot>
  </en-form>
</template>

<script lang="ts">
import { assign, filter } from 'lodash-es'

export default factory({
  props: {
    code: String as PropType<string>,
    model: Object as PropType<Record<string, any>>,
    loading: Boolean as PropType<boolean>,
    rules: Object as PropType<Record<string, any>>,
    config: Object as PropType<Record<string, any>>
  },

  config: {
    children: {
      fields: {
        ajax: {
          get: {
            action: 'GET /enocloud/form/fields/setting',
            data: 'array',
            loading: true,
            params(params) {
              params.payload = { formFieldsType: this.code }
            }
          }
        },
        computed: {
          visibleFields() {
            return filter(
              this.fields.data.map((item) => {
                const { serviceFormFieldsType } = item
                const config = this.config?.[serviceFormFieldsType?.code!]
                if (config) {
                  item.serviceFormFieldsType!.prop = config.prop ?? item.serviceFormFieldsType!.prop
                  item.shown!.value = config.visible ?? item.shown!.value
                }
                return item
              }),
              ['shown.value', true]
            )
          },
          normalizedRules() {
            return assign(
              this.fields.visibleFields.reduce(
                (rules, field) => {
                  const { required, serviceFormFieldsType } = field
                  if (required?.value) {
                    rules[serviceFormFieldsType!.prop] = {
                      required: true,
                      message: `请填写${serviceFormFieldsType?.message}`
                    }
                  }
                  return rules
                },
                {} as Record<string, any>
              ),
              this.rules
            )
          }
        }
      }
    }
  },

  methods: {
    clearValidate() {
      return this.refs.form.clearValidate()
    },
    validate() {
      return this.refs.form.validate()
    }
  },

  mounted() {
    this.fields.get()

  }
})
</script>
