<template>
  <en-drawer :model-value="modelValue" title="新建套餐订单" direction="btt" @close="$emit('update:model-value', false)" size="80%">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" class="grid grid-cols-4 gap-x-6">
      <en-form-item label="套餐名称" prop="mallCombo.id">
        <select-maintain
          v-model="form.data.mallCombo"
          :ajax="{
            action: 'GET /enocloud/mall/combo',
            params: (params, value) => (params.payload = { name: value, statusCode: 'I', offlineOnly: true })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
          @change="form.mallCombo.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="客户名称" prop="customer.id">
        <select-maintain
          v-model="form.data.customer"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (params, value) => (params.payload = { quickSearch: value, rsexp: 'id,name,cellphone,membershipCardNo,ownedVehicles[plateNo]' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
          @change="form.customer.change"
        >
        </select-maintain>
      </en-form-item>
      <en-form-item label="会员卡号">
        <en-input :model-value="form.data.customer?.membershipCardNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="车牌" prop="plateNo">
        <div class="w-full flex flex-col">
          <select-maintain
            v-model="form.data.plateNo"
            :options="form.data.customer?.ownedVehicles"
            :props="{ label: 'plateNo', value: 'plateNo' }"
            class="w-full"
          ></select-maintain>
          <span class="text-red-500">
            选择指定车牌之后，客户购买的套餐只允许指定车牌所用，如没有选择指定车牌，则客户名下所有车辆都可使用购买的套餐。
          </span>
        </div>
      </en-form-item>
      <en-form-item label="销售人员" prop="salesman">
        <select-maintain
          v-model="form.data.salesman"
          :ajax="{ action: 'GET /enocloud/common/user', params: (params, value) => (params.payload = { name: value, status: 'ON' }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="购买份数" prop="count">
        <en-input-number v-model="form.data.count" :min="1" :precision="0" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="套餐简介">
        <span>{{ form.data.mallCombo?.intro || '--' }}</span>
      </en-form-item>
      <en-form-item label="套餐备注">
        <span>{{ form.data.mallCombo?.comment || '--' }}</span>
      </en-form-item>
    </en-form>

    <en-table :data="mallCombo.data.mallComboCoupons" :loading="mallCombo.loading">
      <en-table-column label="卡券名称" prop="mallCoupon.name" width="200"></en-table-column>
      <en-table-column label="卡券类型" prop="mallCoupon.type.message" width="200"></en-table-column>
      <en-table-column label="面值" prop="mallCoupon.faceValue" width="200"></en-table-column>
      <en-table-column label="满减金额" prop="mallCoupon.lowerLimitAmount"></en-table-column>
      <en-table-column label="折扣">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.mallCoupon?.type?.discountMethod.code === 'P' ? row.mallCoupon.discountValue : '--' }}
        </template>
      </en-table-column>
      <en-table-column label="抵扣金额">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.mallCoupon && row.mallCoupon.type && row.mallCoupon.type.discountMethod.code === 'F' ? row.mallCoupon.discountValue : '--' }}
        </template>
      </en-table-column>
      <en-table-column label="项目" prop="mallCoupon.maintenance.name"></en-table-column>
      <en-table-column label="配件" prop="mallCoupon.goods.name"></en-table-column>
      <en-table-column label="有效期类型" prop="expireType.message"></en-table-column>
      <en-table-column label="有效期" width="150">
        <template #default="{ row }: { row: EnocloudMallDefinitions['MallComboCouponDto'] }">
          {{ row.expireType?.code === 'F' ? `${row.startDate}至${row.endDate}` : `${row.validityDays}天` }}
        </template>
      </en-table-column>
      <en-table-column label="本次发放总量" prop="totalCount"></en-table-column>
      <en-table-column label="单次领取量" prop="count"></en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
const formDataInit = (): EnocloudMallDefinitions['MallComboOrderDto'] => {
  return {
    mallCombo: {
      intro: '',
      comment: ''
    },
    customer: {
      ownedVehicles: []
    },
    membershipCardNo: '',
    plateNo: '',
    salesman: {},
    count: 1
  } as any
}

export default factory({
  props: {
    modelValue: Boolean,
    customer: Object as PropType<EnocloudMallDefinitions['CustomerDto'] | EnocloudServiceDefinitions['CustomerDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      async handler(visible) {
        if (visible) {
          if (this.customer) {
            const res = await this.ajax('GET /enocloud/common/customer/:customerId', { paths: [this.customer.id] })
            this.form.data.customer = res.data[0] as EnocloudMallDefinitions['CustomerDto']
          }
        } else {
          this.form.data = formDataInit()
          this.mallCombo.data.mallComboCoupons = []
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: formDataInit(),
        rules: {
          'customer.id': [{ type: 'number', required: true, message: '请选择客户名称' }],
          'salesman.id': [{ type: 'number', required: true, message: '请选择销售人员' }],
          'mallCombo.id': [{ type: 'number', required: true, message: '请选择套餐名称' }],
          count: [{ required: true, message: '请输入最少购买份数', trigger: 'blur' }]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/mall/combo/order',
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          mallCombo: {
            change() {
              this.mallCombo.get()
            }
          },
          customer: {
            change() {
              this.form.data.plateNo = ''
            }
          }
        }
      },
      mallCombo: {
        ajax: {
          get: {
            action: 'GET /enocloud/mall/combo/:mallComboId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.form.data.mallCombo?.id]
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.submit()
              this.footer.cancel.click()
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
