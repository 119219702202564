<template>
  <en-dialog :model-value="modelValue" title="添加配件" @close="$emit('update:model-value', false)" width="80%">
    <template #operation>
      <!-- <en-button type="primary" link @click="operation.create.click">新增配件</en-button> -->
    </template>

    <form-query :model="form.data" :method="table.get">
      <en-form-item label="OE号">
        <en-input v-model="form.data.oem"></en-input>
      </en-form-item>
      <en-form-item label="编码">
        <en-input v-model="form.data.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="名称">
        <en-input v-model="form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="批次">
        <en-input v-model="form.data.primaryVehicleSpec"></en-input>
      </en-form-item>
      <en-form-item label="适用车型">
        <en-input v-model="form.data.primaryVehicleSpec"></en-input>
      </en-form-item>
      <en-form-item label="库存搜索" prop="inMaterialOnly">
        <select-maintain
          v-model="form.data.inMaterialOnly"
          :options="[
            { message: '非0库存', code: 'Y' },
            { message: '0库存', code: 'N' }
          ]"
          :props="{ label: 'message', value: '' }"
          value-key="code"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="仓库">
            <select-maintain
              v-model="form.data.warehouseId"
              :ajax="{
                action: 'GET /enocloud/common/warehouse',
                params: (params, value) => (params.payload = { name: value })
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
    </form-query>

    <table-dynamic
      code="IVPKFD"
      :data="table.data"
      :loading="table.loading"
      pagination
      :paging="table.paging"
      :method="table.get"
      @row-click="table.row.click"
    >
    <template #GOODS_TYPE="{ row }: { row: GoodsSelectionOption }">{{ row.goods?.type?.message}}</template></table-dynamic>

    <div class="h-4"></div>

    <table-dynamic code="IVPKDFD" :data="selection.data">
      <template #OPERATION="{ $index }: { $index: number }">
        <en-button type="primary" link @click="selection.delete.click($index)">删除</en-button>
      </template>
      <template #NAME="{ row }: { row: GoodsSelectionOption }">{{ row.goods.name }}</template>
      <template #SERIAL_NO="{ row }: { row: GoodsSelectionOption }">{{ row.goods.serialNo }}</template>
      <template #TYPE="{ row }: { row: GoodsSelectionOption }">{{ row.goods.type?.message }}</template>
      <template #SERVICE_PRICE="{ row }: { row: GoodsSelectionOption }">
        <en-input-number v-model="row.servicePrice" :min="0" class="w-full"></en-input-number>
      </template>

      <template #BATCH_NO="{ row }: { row: GoodsSelectionOption }">
        <select-maintain
          v-model="row.batch"
          :options="row.goods?.batches"
          :props="{ label: 'batchNo', value: '' }"
          :clearable="false"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </template>

      <template #INVENTORY_COUT="{ row }: { row: GoodsSelectionOption }">
        <en-input-number v-model="row.count" :min="0" class="w-full"></en-input-number>
      </template>

      <template #GOODS_SPECIFICATION_NAME="{ row }: { row: GoodsSelectionOption }">
        <select-maintain
          v-model="row.goodsSpecification"
          :options="row.goods.specifications"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </template>
 
      <template #PRICE="{ row }: { row: GoodsSelectionOption }">
        <select-maintain
          v-model="row.price"
          :ajax="{
            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
            params: (params) => (params.paths = [row.goodsSpecification?.id])
          }"
          :props="{ label: 'price', value: 'price' }"
          allow-create
          lazy
          class="w-full"
        >
          <template #default="{ option }: { option: EnocloudCommonDefinitions['PriceDto'] }">
            <div class="flex justify-between gap-6">
              <span>{{ option.price }}</span>
              <span class="text-neutral-500">{{ option.type?.message }}</span>
            </div>
          </template>
        </select-maintain>
      </template>
    </table-dynamic>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>

  <goods-create v-model:visible="create.visible" @confirm="table.get"></goods-create>
</template>

<script lang="ts">
import type { GoodsSelectionOption } from '@business/types'

import GoodsCreate from '@business/components/goods-create.vue'

export default factory({
  components: { GoodsCreate },

  props: {
    modelValue: Boolean,
    type: String as PropType<'purchase' | 'sale' | 'service' | 'stock-taking' | 'stock-transfer' | 'adjustment'>,
    params: Object as Record<string, any>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: GoodsSelectionOption[]) => typeof value === 'object'
  },

  config: {
    children: {
      operation: {
        create: {
          click() {
            this.create.visible = true
          }
        }
      },
      form: {
        data: {
          name: '',
          serialNo: '',
          oem: '',
          primaryVehicleSpec: '',
          inMaterialOnly: 'Y',
          warehouseId:''

        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/inventory',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign(this.form.data, this.params)
            }
          }
        },
        children: {
          row: {
            click(row: EnocloudInventoryDefinitions['InventoryDto']) {
              const exist = this.selection.data.find((item) => item.goods.id === row.goods?.id)
              if (exist) {
                exist.count += 1
              } else {
                let goodsSpecification: EnocloudCommonDefinitions['GoodsSpecificationDto'] | undefined

                switch (this.type) {
                  case 'purchase':
                    goodsSpecification = row.goods?.specifications?.find((item) => item.defaultPurchase?.value)
                    break
                  case 'sale':
                    goodsSpecification = row.goods?.specifications?.find((item) => item.defaultSale?.value)
                    break
                  case 'service':
                    goodsSpecification = row.goods?.specifications?.find((item) => item.defaultService?.value)
                    break
                  case 'stock-taking':
                    goodsSpecification = row.goods?.specifications?.find((item) => item.defaultStockTaking?.value)
                    break
                  case 'stock-transfer':
                    goodsSpecification = row.goods?.specifications?.find((item) => item.defaultStockTransfer?.value)
                    break
                }

                goodsSpecification ??= row.goods?.specifications?.[0]

                this.selection.data.push({
                  goods: row.goods!,
                  count: 1,
                  goodsSpecification,
                  price: 0,
                  servicePrice: 0,
                  batch: row.goods?.batches[0]
                })
              }
            }
          }
        }
      },
      selection: {
        data: [] as GoodsSelectionOption[],
        delete: {
          click(index: number) {
            this.selection.data.splice(index, 1)
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          click() {
            this.emit('confirm', this.selection.data)
            this.footer.cancel.click()
          }
        }
      },
      create: {
        visible: false
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
