<template>
  <en-drawer :model-value="modelValue" title="编辑价格" @close="$emit('update:model-value', false)" width="80%">
    <en-form label-position="right" class="grid grid-cols-2">
      <en-form-item label="编码">{{ data?.serialNo }}</en-form-item>
      <en-form-item label="配件名称">{{ data?.name }}</en-form-item>
      <en-form-item label="基本单位">{{ data?.warehouseUnit }}</en-form-item>
      <en-form-item label="规格单位">{{ specification?.name }}</en-form-item>
    </en-form>

    <en-form :model="form.data">
      <template v-if="store.accessRightsHash.PRICE_PURCHASE">
        <en-form-item label="进价一">
          <en-input v-model="form.data.purchasePrice1"></en-input>
        </en-form-item>
        <en-form-item label="进价二">
          <en-input v-model="form.data.purchasePrice2"></en-input>
        </en-form-item>
        <en-form-item label="进价三">
          <en-input v-model="form.data.purchasePrice3"></en-input>
        </en-form-item>
      </template>

      <template v-if="store.accessRightsHash.PRICE_SALE">
        <en-form-item label="销价一">
          <en-input v-model="form.data.salePrice1"></en-input>
        </en-form-item>
        <en-form-item label="销价二">
          <en-input v-model="form.data.salePrice2"></en-input>
        </en-form-item>
        <en-form-item label="销价三">
          <en-input v-model="form.data.salePrice3"></en-input>
        </en-form-item>
      </template>

      <template v-if="store.accessRightsHash.PRICE_SERVICE">
        <en-form-item label="维修价格">
          <en-input v-model="form.data.servicePrice"></en-input>
        </en-form-item>
      </template>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :loading="form.loading" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: [Object, null] as PropType<EnocloudInventoryDefinitions['GoodsDto'] | null>,
    specification: [Object, null] as PropType<EnocloudInventoryDefinitions['GoodsSpecificationDto'] | null>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (specification: EnocloudInventoryDefinitions['GoodsSpecificationDto']) => typeof specification === 'object'
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue && this.specification?.id) {
          this.form.init()
          Object.assign(this.form.data, this.specification)
          const res = await this.ajax('GET /enocloud/common/goods/specification/:specificationId/price', { paths: [this.specification?.id] })
          this.form.data.purchasePrice1 = res.data.find((item) => item.type?.code == 'PP1')?.price ?? 0
          this.form.data.purchasePrice2 = res.data.find((item) => item.type?.code == 'PP2')?.price ?? 0
          this.form.data.purchasePrice3 = res.data.find((item) => item.type?.code == 'PP3')?.price ?? 0
          this.form.data.salePrice1 = res.data.find((item) => item.type?.code == 'SP1')?.price ?? 0
          this.form.data.salePrice2 = res.data.find((item) => item.type?.code == 'SP2')?.price ?? 0
          this.form.data.salePrice3 = res.data.find((item) => item.type?.code == 'SP3')?.price ?? 0
          this.form.data.servicePrice = res.data.find((item) => item.type?.code == 'SVP')?.price ?? 0
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          purchasePrice1: 0,
          purchasePrice2: 0,
          purchasePrice3: 0,
          salePrice1: 0,
          salePrice2: 0,
          salePrice3: 0,
          salePriceLowest: 0,
          servicePrice: 0,
          weight: 0
        },
        ajax: {
          submit: {
            action: 'PUT /enocloud/common/goods/specification/:goodsSpecificationId/addition',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.specification?.id]
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            return this.form.submit().then(() => {
              this.footer.cancel.click()
              this.emit('confirm', this.form.data)
            })
          }
        }
      }
    }
  }
})
</script>
