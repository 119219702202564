<template>
  <en-dialog :model-value="modelValue" title="添加配件" @close="$emit('update:model-value', false)" width="80%">
    <template #operation>
      <en-button type="primary" link @click="operation.create.click">新增配件</en-button>
    </template>

    <form-query :model="form.data" :method="table.get">
      <en-form-item label="OE号">
        <en-input v-model="form.data.oem"></en-input>
      </en-form-item>
      <en-form-item label="编码">
        <en-input v-model="form.data.serialNo"></en-input>
      </en-form-item>
      <en-form-item label="名称">
        <en-input v-model="form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="批次">
        <en-input v-model="form.data.primaryVehicleSpec"></en-input>
      </en-form-item>

      <en-form-item label="适用车型">
        <en-input v-model="form.data.primaryVehicleSpec"></en-input>
      </en-form-item>
      <en-form-item label="库存搜索">
        <select-maintain
          v-model="form.data.inMaterialOnly"
          :options="[
            { message: '非0库存', code: 'Y' },
            { message: '0库存', code: 'N' }
          ]"
          :props="{ label: 'message', value: 'code' }"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="仓库">
        <select-maintain
          v-model="form.data.warehouseId"
          :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, name) => (params.payload = { name }) }"
          :props="{ label: 'name', value: 'id' }"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </form-query>

    <table-dynamic
      code="GDSPKFD"
      :data="table.data"
      :loading="table.loading"
      pagination
      :paging="table.paging"
      :method="table.get"
      @row-click="table.row.click"
    >
      <template #INVENTORY_COUT="{ row }: { row: EnocloudCommonDefinitions['GoodsDto'] }">{{ table.invetory.count(row) }}</template>
      <template #VEHICLE_SPEC="{ row }: { row: EnocloudCommonDefinitions['GoodsDto'] }">{{ row.primaryVehicleSpec?.join(',') }}</template>
      <template #SERVICE_PRICE="{ row }: { row: EnocloudCommonDefinitions['GoodsDto'] }">{{ formatMoney(table.service.price(row)) }}</template>
    </table-dynamic>

    <table-dynamic
      code="GDSPKDFD"
      :data="selection.data"
      :config="{
        PRICE: {
          visible: () => type === 'purchase' || type === 'sale' || type === 'stock-taking' || type === 'stock-transfer'
        }
      }"
    >
      <template #OPERATION="{ $index }: { $index: number }">
        <en-button type="primary" link @click="selection.delete.click($index)">删除</en-button>
      </template>

      <template #NAME="{ row }: { row: IGoodsSelectionOption }">{{ row.goods.name }}</template>

      <template #SERIAL_NO="{ row }: { row: IGoodsSelectionOption }">{{ row.goods.serialNo }}</template>

      <template #TYPE="{ row }: { row: IGoodsSelectionOption }">{{ row.goods.type?.message }}</template>

      <template #SERVICE_PRICE="{ row }: { row: IGoodsSelectionOption }">
        <en-input-number v-model="row.servicePrice" :min="0" class="w-full"></en-input-number>
      </template>

      <template #INVENTORY_COUT="{ row }: { row: IGoodsSelectionOption }">
        <en-input-number v-model="row.count" :min="0" class="w-full"></en-input-number>
      </template>

      <template #VEHICLE_SPEC="{ row }: { row: IGoodsSelectionOption }">
        {{ row.goods?.primaryVehicleSpec?.join(',') }}
      </template>

      <template #GOODS_SPECIFICATION_NAME="{ row }: { row: IGoodsSelectionOption }">
        <select-maintain
          v-model="row.goodsSpecification"
          :options="row.goods.specifications"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          :clearable="false"
          class="w-full"
        ></select-maintain>
      </template>

      <template #PRICE="{ row }: { row: IGoodsSelectionOption }">
        <select-maintain
          v-model="row.price"
          :ajax="{
            action: 'GET /enocloud/common/goods/specification/:specificationId/price',
            params: (params) => (params.paths = [row.goodsSpecification?.id])
          }"
          :props="{ label: 'price', value: 'price' }"
          allow-create
          lazy
          :disabled="!row.goodsSpecification?.id"
        >
          <template #default="{ option }: { option: EnocloudCommonDefinitions['PriceDto'] }">
            <div class="flex justify-between gap-6">
              <span>{{ option.price }}</span>
              <span class="text-neutral-500">{{ option.type?.message }}</span>
            </div>
          </template>
        </select-maintain>
      </template>
    </table-dynamic>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>

  <goods-create v-model:visible="create.visible" @confirm="table.get"></goods-create>
</template>

<script lang="ts">
import { find } from 'lodash-es'
import { calculator } from '@enocloud/utils'
import type { GoodsSelectionOption } from '@business/types'

import GoodsCreate from '@business/components/goods-create.vue'

type IGoodsSelectionOption = GoodsSelectionOption

export default factory({
  components: { GoodsCreate },

  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['PurchaseDto']>,
    type: String as PropType<'purchase' | 'sale' | 'service' | 'quotation' | 'stock-taking' | 'stock-transfer'>,
    inMaterialOnly: Boolean as PropType<boolean>,
    params: Object as Record<string, any>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: IGoodsSelectionOption[]) => typeof value === 'object'
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!value) this.selection.data = []
      }
    }
  },

  computed: {
    defaultSpecification() {
      switch (this.type) {
        case 'purchase':
          return 'defaultPurchase'
        case 'sale':
          return 'defaultSale'
        case 'service':
        case 'quotation':
          return 'defaultService'
        case 'stock-taking':
          return 'defaultStockTaking'
        case 'stock-transfer':
          return 'defaultStockTransfer'
        default:
          return 'nolimit'
      }
    }
  },

  config: {
    operation: {
      create: {
        click() {
          this.create.visible = true
        }
      }
    },

    children: {
      form: {
        data: {
          name: '',
          serialNo: '',
          oem: '',
          primaryVehicleSpec: '',
          inMaterialOnly: 'Y',
          statusCode: 'A'
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/goods',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign(this.form.data, this.params)
              params.payload.inMaterialOnly = this.inMaterialOnly ? 'Y' : ''
            }
          }
        },
        children: {
          row: {
            click(row: EnocloudCommonDefinitions['GoodsDto']) {
              const exist = this.selection.data.find((item) => item.goods.id === row.id)
              if (exist) {
                exist.count += 1
              } else {
                let goodsSpecification: EnocloudCommonDefinitions['GoodsSpecificationDto'] | undefined

                switch (this.type) {
                  case 'purchase':
                    goodsSpecification = row.specifications?.find((item) => item.defaultPurchase?.value)
                    break
                  case 'sale':
                    goodsSpecification = row.specifications?.find((item) => item.defaultSale?.value)
                    break
                  case 'service':
                    goodsSpecification = row.specifications?.find((item) => item.defaultService?.value)
                    break
                  case 'stock-taking':
                    goodsSpecification = row.specifications?.find((item) => item.defaultStockTaking?.value)
                    break
                  case 'stock-transfer':
                    goodsSpecification = row.specifications?.find((item) => item.defaultStockTransfer?.value)
                    break
                }

                goodsSpecification ??= row.specifications?.[0]

                this.selection.data.push({
                  goods: row,
                  count: 1,
                  goodsSpecification,
                  price: 0,
                  servicePrice: 0
                })
              }
            }
          },
          invetory: {
            count(row: EnocloudCommonDefinitions['GoodsDto']) {
              const inventoryConfig = row.inventoryConfigs?.[0]
              const batchCount = row.batches.reduce((count, item) => calculator.add(count, item.count), 0)
              return inventoryConfig?.totalCount ?? batchCount
            }
          },
          service: {
            price(row: EnocloudCommonDefinitions['GoodsDto']) {
              const specification = find(row.specifications, `${this.defaultSpecification}.value`) || row.specifications?.[0]
              return specification?.servicePrice
            }
          }
        }
      },
      selection: {
        data: [] as IGoodsSelectionOption[],
        delete: {
          click(index: number) {
            this.selection.data.splice(index, 1)
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          click() {
            this.emit('confirm', this.selection.data)
            this.footer.cancel.click()
          }
        }
      },
      create: {
        visible: false
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
