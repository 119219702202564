<template>
  <tabs-maintain
    v-model="active"
    :options="options"
    :props="{ label: 'message', value: 'code' }"
    :config="{ order: 'none' }"
    :disabled="loading"
    @change="onActiveChange"
  ></tabs-maintain>

  <div class="flex items-center">
    <en-button v-for="item of types" :type="typeActive === item.code ? 'primary' : ''" @click="typeActive = item.code">
      {{ item.message }}
    </en-button>
    <span class="w-5"></span>
    <el-checkbox-group v-model="surfaceCodes">
      <el-checkbox v-for="item of codes" :label="`${active}_${typeActive}_W_${item.code}`">
        {{ item.message }}
      </el-checkbox>
    </el-checkbox-group>
  </div>

  <div v-loading="loading" class="mt-5 flex gap-5 justify-between">
    <div v-for="name of names" class="w-100 border border-dashed rounded-lg">
      <div class="text-center">{{ name.message }}</div>
      <div class="mt-5 flex">
        <spray-surface-panel v-model:codes="surfaceCodes" :name="name.code"></spray-surface-panel>
        <spray-surface-checkbox v-model:codes="surfaceCodes" :name="name.code"></spray-surface-checkbox>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onMounted } from 'vue'
import { head, find, map, reduce, last } from 'lodash-es'

import { EnMessageBox } from '@enocloud/components'
import { ajax } from '@enocloud/utils'
import SpraySurfacePanel from '@business/components/spray-surface-panel.vue'
import SpraySurfaceCheckbox from '@business/components/spray-surface-checkbox.vue'

type LookUpDto = EnocloudCommonDefinitions['LookupDto']

type ILookup = Pick<LookUpDto, 'code' | 'message'>

interface IOption extends ILookup {
  codes: ILookup[]
  types: (ILookup & { positions: ILookup[] })[]
}

interface Props {
  modelValue?: string[]
}

interface Emits {
  (e: 'update:model-value', value: string[]): void
  (e: 'change', value: string[]): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const typeOptions: ILookup[] = [
  { code: 'P', message: '喷漆' },
  { code: 'B', message: '美容抛光' },
  { code: 'S', message: '点修补' }
]

const codeOptions: ILookup[] = [
  { code: 'WSFC', message: '全车油漆' },
  { code: 'WMFC', message: '全车改色' },
  { code: 'WHOLE', message: '全车抛光' }
]

const positionOptions: ILookup[] = [
  { code: 'L', message: '左侧图' },
  { code: 'O', message: '俯视图' },
  { code: 'R', message: '右侧图' },
  { code: 'I', message: '内侧图' }
]

const extract = (options: ILookup[], codes: string[] = []) => {
  return codes.length === 0
    ? options
    : reduce(
        options,
        (res, item) => {
          if (codes.includes(item.code)) res.push(item)
          return res
        },
        [] as ILookup[]
      )
}

const options: IOption[] = [
  {
    code: 'CR',
    message: '轿车',
    codes: extract(codeOptions),
    types: map(extract(typeOptions), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, option.code === 'P' ? [] : ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'SV',
    message: 'SUV',
    codes: extract(codeOptions),
    types: map(extract(typeOptions), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, option.code === 'P' ? [] : ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'BV',
    message: '商务车',
    codes: extract(codeOptions),
    types: map(extract(typeOptions), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, option.code === 'P' ? [] : ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'MV',
    message: '微面',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P', 'S']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, option.code === 'P' ? [] : ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'LB',
    message: '轻客',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions)
      }
    })
  },
  {
    code: 'PC',
    message: '皮卡(加盖)',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions)
      }
    })
  },
  {
    code: 'PK',
    message: '皮卡(不加盖)',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions)
      }
    })
  },
  {
    code: 'MB',
    message: '摩托车',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L'])
      }
    })
  },
  {
    code: 'MP',
    message: '小客车',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'BP',
    message: '大客车(9m)',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'GP',
    message: '大客车(12m)',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'MX',
    message: '搅拌车',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'TK',
    message: '卡车',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L', 'O', 'R'])
      }
    })
  },
  {
    code: 'ST',
    message: '沙土',
    codes: extract(codeOptions, ['WSFC', 'WMFC']),
    types: map(extract(typeOptions, ['P']), (option) => {
      return {
        code: option.code,
        message: option.message,
        positions: extract(positionOptions, ['L', 'O', 'R'])
      }
    })
  }
]

const loading = ref(false)
const lookupMap = new Map<string, ILookup[]>()
const active = ref<string | number>()

const onActiveChange = async (name: string | number) => {
  typeActive.value = find(options, ['code', `${name}`])?.types[0].code ?? ''

  const lmv = lookupMap.get(`${name}`)
  if (!lmv) {
    loading.value = true
    try {
      const res = await ajax('GET /enocloud/common/maintenance', { payload: { spraySurface: `${name}` } })
      lookupMap.set(
        `${name}`,
        res.data.map((item) => ({ code: item.spraySurface!, message: item.name! }))
      )
    } finally {
      loading.value = false
    }
  }
}

const typeActive = ref('')
const types = computed(() => find(options, ['code', active.value])?.types ?? [])

const codes = computed(() => find(options, ['code', active.value])?.codes ?? [])

const positions = computed(() => find(types.value, ['code', typeActive.value])?.positions ?? [])

const names = computed(() => {
  return active.value && typeActive.value
    ? positions.value.map((p) => ({ code: `${active.value}-${typeActive.value}-${p.code}`, message: p.message }))
    : []
})

const surfaceCodes = computed({
  get() {
    return props.modelValue
  },
  async set(value) {
    let _data: string[] = value ?? []

    if (value && value.length > 1) {
      const activeType = head(value)!.split('_')[0]
      const pendingType = last(value)!.split('_')[0]

      if (activeType !== pendingType) {
        try {
          await EnMessageBox.confirm('勾选新车型钣面，之前所选钣面将被清除, 是否继续?', '提示')
          _data = _data?.slice(props.modelValue?.length)
        } catch (e) {
          _data = props.modelValue ?? []
        }
      }
    }

    emits('update:model-value', _data)
    emits('change', _data)
  }
})

onMounted(() => {
  active.value = head(head(props.modelValue)?.split('_')) || head(options)?.code
})
</script>
