<template>
  <en-dialog :model-value="modelValue" title="添加配件" @close="$emit('update:model-value', false)" width="80%">
    <form-query :model="form.data" :method="table.get">
      <en-form-item label="项目名称">
        <en-input v-model="form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="项目编码">
        <en-input v-model="form.data.serialNo"></en-input>
      </en-form-item>
    </form-query>

    <en-table :data="table.data" :height="300" :loading="table.loading" :paging="table.paging" pagination @row-click="table.row.click">
      <en-table-column label="项目名称" prop="name"></en-table-column>
      <en-table-column label="项目编号" prop="serialNo"></en-table-column>
    </en-table>

    <en-table :data="selection.data" :height="300">
      <en-table-column label="操作">
        <template #default="{ $index }: { $index: number }">
          <en-button type="primary" link @click="selection.delete.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="项目名称" prop="maintenance.name"></en-table-column>
      <en-table-column label="工时" prop="laborHour">
        <template #default="{ row }: { row: IMaintenanceSelectionOption }">
          <en-input-number v-model="row.laborHour"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="单价" prop="price">
        <template #default="{ row }: { row: IMaintenanceSelectionOption }">
          <en-input-number v-model="row.price"></en-input-number>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-dialog>
</template>

<script lang="ts">
import { find } from 'lodash-es'
import { type MaintenanceSelectionOption } from '@business/types'

type IMaintenanceSelectionOption = MaintenanceSelectionOption<EnocloudCommonDefinitions['MaintenanceDto']>

export default factory({
  props: {
    modelValue: Boolean,
    ignoreSpraySurface: Boolean as PropType<boolean>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: IMaintenanceSelectionOption[]) => typeof value === 'object'
  },

  watch: {
    modelValue: {
      handler(value) {
        if (!value) this.selection.data = []
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          name: '',
          serialNo: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/maintenance',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { ...this.form.data }
              params.payload.ignoreSpraySurface = this.ignoreSpraySurface
            }
          }
        },
        children: {
          row: {
            click(row: EnocloudCommonDefinitions['MaintenanceDto']) {
              const exist = find(this.selection.data, ['maintenance.id', row.id])
              if (!exist) this.selection.data.push({ maintenance: row, laborHour: 1, price: 0 })
            }
          }
        }
      },
      selection: {
        data: [] as IMaintenanceSelectionOption[],
        delete: {
          click(index: number) {
            this.selection.data.splice(index, 1)
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          click() {
            this.emit('update:model-value', false)
            this.emit('confirm', this.selection.data)
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
