<template>
  <en-drawer title="车辆档案" direction="btt" size="80%" :model-value="modelValue" @close="$emit('update:model-value', false)">
    <div class="bg-blue-50 p-8 flex flex-col gap-2">
      <div class="flex gap-5">
        <span class="font-black text-lg mt--.7">{{ vehicle.data.plateNo }}</span>
        <span>{{ vehicle.data.vehicleSpec?.map((item) => (item as string).replace(/[\^|]/g, ',')).join(',') }}</span>
      </div>

      <div class="flex gap-30">
        <div class="flex items-center gap-6">
          <img src="@business/assets/icon-mileage.png" class="w-8 h-8" />
          <div class="flex flex-col gap-1">
            <span>里程</span>
            <span>{{ vehicle.data.currentMiles || '0' }}</span>
          </div>
        </div>
        <div class="flex items-center gap-6">
          <img src="@business/assets/icon-service-fee.png" class="w-8 h-8" />
          <div class="flex flex-col gap-1">
            <span>维修总费用</span>
            <span>{{ formatMoney(vehicle.data.serviceTotalAmount) }}</span>
          </div>
        </div>
        <div class="flex items-center gap-6">
          <img src="@business/assets/icon-pay-fee.png" class="w-8 h-8" />
          <div class="flex flex-col gap-1">
            <span>应收款</span>
            <span>{{ formatMoney(vehicle.data.serviceReceivableAmount) }}</span>
          </div>
        </div>
        <div class="flex items-center gap-6">
          <img src="@business/assets/icon-service-count.png" class="w-8 h-8" />
          <div class="flex flex-col gap-1">
            <span>进厂次数</span>
            <span>{{ vehicle.data.serviceAcceptCount }}</span>
          </div>
        </div>
      </div>
    </div>

    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="tabs.active">
          <en-tab-pane label="车辆信息" name="vehicle">
            <en-scrollbar :height="height - 55">
              <vehicle-form
                :model-value="vehicle.data"
                :loading="vehicle.loading"
                :ref="setRef('vehicleForm')"
                class="grid grid-cols-4 gap-x-6"
                @change="vehicle.change"
              ></vehicle-form>
            </en-scrollbar>
          </en-tab-pane>
          <en-tab-pane label="历史工单" name="workorder">
            <table-dynamic
              code="SVCQAM"
              :height="height - 55"
              :data="workorder.data"
              :method="workorder.get"
              :paging="workorder.paging"
              :loading="workorder.loading"
              :config="{
                OPERATION: { visible: false }
              }"
            >
              <template #SETTLEMENT_PROGRESS="{ row }: { row: EnocloudServiceDefinitions['ServiceQueryDto'] }">
                {{ row.settlementProgress?.message }}
              </template>
            </table-dynamic>
          </en-tab-pane>
          <en-tab-pane label="历史维修项目" name="maintenance">
            <table-dynamic
              code="SRDMTHF"
              :height="height - 55"
              :data="maintenance.data"
              :method="maintenance.get"
              :paging="maintenance.paging"
              :loading="maintenance.loading"
              pagination
            ></table-dynamic>
          </en-tab-pane>
          <en-tab-pane label="历史维修配件" name="goods">
            <table-dynamic
              code="SRDMRHF"
              :height="height - 55"
              :data="goods.data"
              :method="goods.get"
              :paging="goods.paging"
              :loading="goods.loading"
            ></table-dynamic>
          </en-tab-pane>
          <en-tab-pane label="回访记录" name="visit">
            <en-table :height="height - 55" :data="visit.data" :method="visit.get" :paging="visit.paging" pagination :loading="visit.loading">
              <en-table-column label="结算时间" width="80">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                  {{ formatDate(row.service?.settlementDatetime) }}
                </template>
              </en-table-column>
              <en-table-column label="单据编号" prop="service.serialNo" width="120"></en-table-column>
              <en-table-column label="客户名称" prop="service.customer.name" width="80"></en-table-column>
              <en-table-column label="性别" prop="service.customer.gender.message" width="60"></en-table-column>
              <en-table-column label="联系人手机" prop="service.customer.cellphone" width="100"></en-table-column>
              <en-table-column label="车牌号" prop="service.vehicle.plateNo"></en-table-column>
              <en-table-column label="车品牌">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                  {{ row.service?.vehicle?.plateNo }}
                </template>
              </en-table-column>
              <en-table-column label="维修类型" prop="service.serviceCategory.name" width="80"></en-table-column>
              <en-table-column label="总金额">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                  {{ formatMoney(row.service?.receivedAmount) }}
                </template>
              </en-table-column>
              <en-table-column label="回访时间">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                  {{ formatDate(row.preparedDatetime, true) }}
                </template>
              </en-table-column>
              <en-table-column label="回访方式" prop="followUpType.message"></en-table-column>
              <en-table-column label="回访专员" prop="preparedBy.name" width="80"></en-table-column>
              <en-table-column label="回访状态">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceReturnVisitHistoryDto'] }">
                  {{ row.status?.code === 'Y' ? '成功' : '失败' }}
                </template>
              </en-table-column>
              <en-table-column label="回访备注" prop="comment"> </en-table-column>
              <en-table-column label="总体评价" prop="row.level" width="180"></en-table-column>
            </en-table>
          </en-tab-pane>
          <en-tab-pane label="保险记录" name="policy">
            <en-table :height="height - 55" :data="policy.data" :method="policy.get" :paging="policy.paging" pagination :loading="policy.loading">
              <en-table-column label="商险承保公司" prop="commercialInsuranceCompany.name"></en-table-column>
              <en-table-column label="交险承保公司" prop="compulsoryInsuranceCompany.name"></en-table-column>
              <en-table-column label="车牌" prop="vehicle.plateNo"></en-table-column>
              <en-table-column label="车主" prop="customer.name"></en-table-column>
              <en-table-column label="手机" prop="customer.cellphone"></en-table-column>
              <en-table-column label="车品牌">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['PolicyDto'] }">{{ row.vehicle?.plateNo }}</template>
              </en-table-column>
              <en-table-column label="状态" prop="status.message"></en-table-column>
              <en-table-column label="保费">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['PolicyDto'] }">{{ formatMoney(row.totalAmount) }}</template>
              </en-table-column>
              <en-table-column label="服务顾问" prop="advisor.name"></en-table-column>
              <en-table-column label="销售人员" prop="salesman.name"></en-table-column>
              <en-table-column label="制单人" prop="preparedBy.name"></en-table-column>
              <en-table-column label="单据日期" prop="preparedDatetime">
                <template #default="{ row }: { row: EnocloudServiceDefinitions['PolicyDto'] }">{{ formatDate(row.preparedDatetime) }}</template>
              </en-table-column>
              <en-table-column label="备注" prop="comment"></en-table-column>
            </en-table>
          </en-tab-pane>
          <en-tab-pane label="服务记录" name="remind">
            <en-table :height="height - 55" :data="remind.data" :method="remind.get" :paging="remind.paging" pagination :loading="remind.loading">
              <en-table-column label="客户名称" prop="customerName"></en-table-column>
              <en-table-column label="联系人手机" prop="customerCellphone" width="100"></en-table-column>
              <en-table-column label="车牌号" prop="plateNo"></en-table-column>
              <en-table-column label="车品牌" prop="vehicleSpec"></en-table-column>
              <en-table-column label="服务类型" prop="categoryKey.message"></en-table-column>
              <en-table-column label="到期日期">
                <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordQueryDto'] }">
                  {{ formatDate(row.dueDate) }}
                </template>
              </en-table-column>
              <en-table-column label="服务备注" prop="businessOpportunityComment"></en-table-column>
              <en-table-column label="服务阶段" prop="type.message"></en-table-column>
              <en-table-column label="跟进内容" prop="comment"></en-table-column>
              <en-table-column label="回访专员" prop="preparedByName"></en-table-column>
              <en-table-column label="跟进时间">
                <template #default="{ row }: { row: EnocloudBusinessOpportunityDefinitions['BusinessOpportunityRecordQueryDto'] }">
                  {{ formatDate(row.preparedDatetime) }}
                </template>
              </en-table-column>
            </en-table>
          </en-tab-pane>
          <en-tab-pane label="车况报告" name="report">
            <en-table :height="height - 55" :data="report.data" :method="report.get" :paging="report.paging" pagination :loading="report.loading">
              <en-table-column label="单号" prop="serialNo"></en-table-column>
              <en-table-column label="车牌号" prop="vehicle.plateNo"></en-table-column>
              <en-table-column label="车主" prop="vehicle.owner.name"></en-table-column>
              <en-table-column label="手机号" prop="vehicle.owner.cellphone"></en-table-column>
              <en-table-column label="车架号" prop="vehicle.vin"></en-table-column>
              <en-table-column label="发动机号" prop="vehicle.engineNumber"></en-table-column>
              <en-table-column label="车品牌">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
                  {{ row.serialNo }}
                </template>
              </en-table-column>
              <en-table-column label="检测方案" prop="inspectionPlan.name"></en-table-column>
              <en-table-column label="报告状态" prop="status.message"></en-table-column>
              <en-table-column label="检测人" prop="inspector.name"></en-table-column>
              <en-table-column label="检测日期" prop="inspectDate">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['VehicleInspectionReportDto'] }">
                  {{ formatDate(row.inspectDate) }}
                </template>
              </en-table-column>
            </en-table>
          </en-tab-pane>
        </en-tabs>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import VehicleForm from '@business/components/vehicle-form.vue'

export default factory({
  components: { VehicleForm },

  props: {
    modelValue: Boolean,
    data: Object as PropType<{ id?: number; plateNo?: string }>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          if (this.data?.id) {
            this.vehicle.get()
            this.workorder.get()
            this.maintenance.get()
            this.goods.get()
            this.visit.get()
            this.policy.get()
            this.remind.get()
            this.report.get()
          }
        } else {
          this.vehicle.init()
        }
      }
    }
  },

  config: {
    children: {
      tabs: {
        active: 'vehicle'
      },
      vehicle: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId',
            data: 'object',
            loading: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          },
          update: {
            action: 'PUT /enocloud/common/vehicle',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.vehicle.data
            }
          }
        },
        change(value: EnocloudCommonDefinitions['VehicleDto']) {
          Object.assign(this.vehicle.data, value)
        }
      },
      workorder: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { vehicleId: this.data?.id }
            }
          }
        }
      },
      maintenance: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/maintenance/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          }
        }
      },
      goods: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId/material/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          }
        }
      },
      visit: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/return/visit/history',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { vehicleId: this.data?.id }
            }
          }
        }
      },
      policy: {
        ajax: {
          get: {
            action: 'GET /enocloud/policy',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { plateNo: this.data?.plateNo, statusCode: 'S' }
            }
          }
        }
      },
      remind: {
        ajax: {
          get: {
            action: 'GET /enocloud/business/opportunity/record/query',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { vehicleId: this.data?.id }
            }
          }
        }
      },
      report: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/report',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { plateNo: this.data?.plateNo, statusCode: 'C' }
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.refs.vehicleForm.refs.form.validate()
            await this.vehicle.update()
            this.emit('update:model-value', false)
            this.emit('confirm')
          }
        }
      }
    }
  }
})
</script>
