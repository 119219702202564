<template>
  <en-drawer :model-value="modelValue" title="创建客户车辆" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item
        v-for="item of vehicle.fields"
        :label="item.serviceFormFieldsType?.message"
        :prop="item.required?.value ? item.serviceFormFieldsType?.prop : null"
      >
        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_PLATE_NO'">
          <en-input v-model="vehicle.data.plateNo"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_SPEC'">
          <cascader-maintain
            v-model="vehicle.data.vehicleSpec"
            :options="vehicle.vehicleSpec.data"
            :props="{ label: 'name', value: 'name', children: 'models' }"
            allow-create
            class="w-full"
          ></cascader-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_VIN'">
          <div class="w-full flex items-center gap-2">
            <en-input v-model="vehicle.data.vin" @blur="vehicle.vin.blur" class="flex-1" maxlength="17" minlength="17"></en-input>
            <en-icon @click="vehicle.vin.click" class="mr-2"><Search /></en-icon>
          </div>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_ENGINE_NUMBER'">
          <en-input v-model="vehicle.data.engineNumber"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_TYPE'">
          <select-maintain
            v-model="vehicle.data.type"
            :ajax="{ action: 'GET /enocloud/common/vehicle/type', params: (params) => (params.payload = { pagingEnabled: false }) }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            class="w-full"
          >
          </select-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_COLOR'">
          <en-input v-model="vehicle.data.color"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_COMPULSORY_INSURANCE_COMPANY'">
          <select-maintain
            v-model="vehicle.data.compulsoryInsuranceCompany"
            :ajax="{
              action: 'GET /enocloud/common/customer',
              params: (params, value) => (params.payload = { name: value, type: 'I' })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_COMPULSORY_INSURANCE_END_DATE'">
          <en-date-picker
            v-model:start="vehicle.data.compulsoryInsuranceStartDate"
            v-model:end="vehicle.data.compulsoryInsuranceEndDate"
            type="daterange"
            class="w-full"
          ></en-date-picker>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_COMMERCIAL_INSURANCE_COMPANY'">
          <select-maintain
            v-model="vehicle.data.commercialInsuranceCompany"
            :ajax="{
              action: 'GET /enocloud/common/customer',
              params: (params, value) => (params.payload = { name: value, type: 'I' })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_COMMERCIAL_INSURANCE_END_DATE'">
          <en-date-picker
            v-model:start="vehicle.data.commercialInsuranceStartDate"
            v-model:end="vehicle.data.commercialInsuranceEndDate"
            type="daterange"
            class="w-full"
          ></en-date-picker>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_ANNUAL_INSPECTION_DATE'">
          <en-date-picker v-model="vehicle.data.annualInspectionDate" class="w-full"></en-date-picker>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_PURCHASING_DATE'">
          <en-date-picker v-model="vehicle.data.purchasingDate" class="w-full"></en-date-picker>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_FRONT_TIRE_MODEL'">
          <en-input v-model="vehicle.data.frontTireModel"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_REAR_TIRE_MODEL'">
          <en-input v-model="vehicle.data.rearTireModel"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'VEHICLE_COLOR_CODE'">
          <en-input v-model="vehicle.data.colorCode"></en-input>
        </template>
      </en-form-item>

      <en-form-item
        v-for="item of customer.fields"
        :label="item.serviceFormFieldsType?.message"
        :prop="item.required?.value ? item.serviceFormFieldsType?.prop : null"
      >
        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_SALESMAN_TYPE'">
          <select-maintain
            v-model="customer.data.salesmanType"
            :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CLKTYPE']) }"
            :props="{ label: 'message', value: '' }"
            value-key="code"
            class="w-full"
          ></select-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_NAME'">
          <en-autocomplete
            v-model="customer.data.name"
            :fetch-suggestions="customer.name.suggestions"
            value-key="name"
            highlight-first-item
            class="w-full"
          ></en-autocomplete>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_CELLPHONE'">
          <en-input v-model="customer.data.cellphone"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_CATEGORY'">
          <select-maintain
            v-model="customer.data.category"
            :ajax="{
              action: 'GET /enocloud/common/customer/category',
              params: (params, value) => (params.payload = { name: value })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_SALESMAN'">
          <en-input
            v-if="customer.data.salesmanType?.code === 'N'"
            :model-value="customer.data.salesman?.name"
            disabled
            @change="customer.salesman.change"
          ></en-input>
          <select-maintain
            v-else-if="customer.data.salesmanType?.code === 'O'"
            :model-value="customer.data.salesman?.name"
            :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['SALSMAN']) }"
            :props="{ label: 'name', value: 'name' }"
            allow-create
            class="w-full"
            @change="customer.salesman.change"
          ></select-maintain>
          <select-maintain
            v-else-if="customer.data.salesmanType?.code === 'I'"
            :model-value="customer.data.salesman?.name"
            :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['CUSISNSSMAN']) }"
            :props="{ label: 'name', value: 'name' }"
            allow-create
            class="w-full"
            @change="customer.salesman.change"
          ></select-maintain>
          <select-maintain
            v-else
            v-model="customer.data.salesman"
            :ajax="{
              action: 'GET /enocloud/common/clerk',
              params: (params, value) => (params.payload = { code: customer.data.salesmanType?.code, name: value })
            }"
            :props="{ label: 'name', value: '' }"
            value-key="id"
            remote
            class="w-full"
          ></select-maintain>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_DOB'">
          <en-date-picker v-model="customer.data.dob" class="w-full"></en-date-picker>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_ADDRESS'">
          <en-input v-model="customer.data.address"></en-input>
        </template>

        <template v-if="item.serviceFormFieldsType?.code === 'CUSTOMER_ADVISOR'">
          <select-maintain
            v-model="customer.data.serviceAdvisor"
            :ajax="{ action: 'GET /enocloud/common/advisorteam/advisor', params: (params, value) => (params.payload = { name: value }) }"
            :props="{ label: 'user.name', value: 'user' }"
            value-key="id"
            class="w-full"
          ></select-maintain>
        </template>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { h } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { EnMessageBox } from '@enocloud/components'

const vinMap = new Map<string, string[]>()

let timeout: NodeJS.Timeout | null

export default factory({
  components: { Search },

  props: {
    modelValue: Boolean
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (res: { vehicle: EnocloudCommonDefinitions['VehicleDto']; customer: EnocloudCommonDefinitions['CustomerDto'] }) =>
      typeof res.customer === 'object' && typeof res.vehicle === 'object'
  },

  config: {
    children: {
      vehicle: {
        fields: [] as EnocloudCommonDefinitions['FormFieldsSettingDto'][],
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/:vehicleId',
            loading: true,
            data: 'object',
            params(params) {
              params.paths = [this.vehicle.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/common/vehicle',
            loading: true,
            params(params) {
              params.payload = this.vehicle.data
            }
          }
        },
        children: {
          vehicleSpec: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/brand',
                data: 'array',
                params(params) {
                  params.payload = { depth: 1 }
                }
              }
            }
          },
          vin: {
            async blur() {
              const vinCode = this.vehicle.data.vin
              if (!vinCode || vinCode.length !== 17) return
              let vehicleSpec = vinMap.get(vinCode)
              if (!vehicleSpec) {
                const data = this.vehicle.vehicleSpec.data
                const res = await this.ajax('GET /enocloud/common/vehicle/spec/:vinCode', { paths: [vinCode] })
                const { subBrand = '', vehicleSys = '' } = res.data[0]
                const current = data.find((item) => item.name === subBrand)
                if (current) {
                  const subCurrent = current.models.find((item) => item.name === vehicleSys)
                  if (subCurrent) {
                    vehicleSpec = [current.name!, subCurrent.name!]
                  } else {
                    current.models.push({ name: vehicleSys })
                    vehicleSpec = [current.name!, vehicleSys]
                  }
                } else {
                  data.push({ name: subBrand, models: [{ name: vehicleSys }] })
                  vehicleSpec = [subBrand, vehicleSys]
                }
                vinMap.set(vinCode, vehicleSpec)
              }
              this.vehicle.data.vehicleSpec = vehicleSpec
            },
            async click() {
              if (this.vehicle.data.vin?.length !== 17) return
              const [res] = (await this.ajax('GET /enocloud/common/vehicle/spec/:vinCode', { paths: [this.vehicle.data.vin] })).data
              if (!res) return
              EnMessageBox({
                title: '车品牌信息',
                message: h(
                  'div',
                  {
                    style: { display: 'flex', 'flex-direction': 'column' }
                  },
                  [
                    h('span', `品牌：${res.brand}`),
                    h('span', `厂牌：${res.subBrand}`),
                    h('span', `车系：${res.vehicleSys}`),
                    h('span', `车组：${res.vehicleGroup}`),
                    h('span', `年款：${res.producedYear}`),
                    h('span', `排量：${res.displacement}`),
                    h('span', `发动机：${res.engine}`),
                    h('span', `变速箱：${res.transmission}`),
                    h('span', `车辆类型：${res.body}`)
                  ]
                )
              })
            }
          }
        }
      },
      customer: {
        fields: [] as EnocloudCommonDefinitions['FormFieldsSettingDto'][],
        ajax: {
          get: {
            action: 'GET /enocloud/common/customer/:customerId',
            data: 'object',
            params(params) {
              params.paths = [this.customer.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/common/customer',
            loading: true,
            params(params) {
              params.payload = this.customer.data
            }
          }
        },
        children: {
          name: {
            suggestions(quickSearch: string, cb: (arg: any) => void) {
              if (timeout) clearTimeout(timeout)
              timeout = setTimeout(async () => {
                cb((await this.ajax('GET /enocloud/common/customer/query', { payload: { quickSearch } })).data)
              }, 1000)
            }
          },
          salesman: {
            change(value: string | EnocloudCommonDefinitions['ClerkDto']) {
              this.customer.data.salesman ??= {}
              if (typeof value === 'string') {
                this.customer.data.salesman.name = value
              } else {
                this.customer.data.salesman = value
              }
            }
          }
        }
      },
      form: {
        rules: {
          'vehicle.plateNo': { required: true, message: '请输入车牌' },
          'vehicle.vehicleSpec': { required: true, message: '请选择车品牌' },
          'vehicle.vin': [
            { required: true, message: '请输入vin码' },
            { min: 17, message: '请输入17位vin码', trigger: 'blur' }
          ],
          'vehicle.engineNumber': { required: true, message: '请输入发动机号' },
          'vehicle.type': {
            type: 'object',
            required: true,
            message: '请选择车型类别',
            fields: {
              name: { required: true }
            }
          },
          'vehicle.color': { required: true, message: '请填写外观颜色' },
          'vehicle.compulsoryInsuranceCompany': {
            type: 'object',
            required: true,
            message: '请选择交险承保',
            fields: {
              id: { required: true }
            }
          },
          'vehicle.compulsoryInsuranceEndDate': { required: true, message: '请选择交险承保日期' },
          'vehicle.commercialInsuranceCompany': {
            type: 'object',
            required: true,
            message: '请选择商险承保',
            fields: {
              id: { required: true }
            }
          },
          'vehicle.commercialInsuranceEndDate': { required: true, message: '请选择商险承保日期' },
          'vehicle.annualInspectionDate': { required: true, message: '请选择车检日期' },
          'vehicle.purchasingDate': { required: true, message: '请选择购买日期' },
          'vehicle.frontTireModel': { required: true, message: '请输入前轮胎型号' },
          'vehicle.rearTireModel': { required: true, message: '请输入后轮胎型号' },
          'vehicle.colorCode': { required: true, message: '请输入色号' },

          'customer.salesmanType': {
            type: 'object',
            required: true,
            message: '请选择客户来源',
            fields: {
              id: { required: true }
            }
          },
          'customer.name': { required: true, message: '请输入车主姓名' },
          'customer.cellphone': { required: true, message: '请输入车主电话' },
          'customer.category': {
            type: 'object',
            required: true,
            message: '请选择客户分类',
            fields: {
              id: { required: true }
            }
          },
          'customer.salesman': {
            type: 'object',
            required: true,
            message: '请填写客户介绍人',
            fields: {
              name: { required: true }
            }
          },
          'customer.dob': { required: true, message: '请选择车主生日' },
          'customer.address': { required: true, message: '请输入车主地址' },
          'customer.serviceAdvisor.name': { required: true, message: '请选择服务管家' }
        },
        computed: {
          data() {
            return {
              customer: this.customer.data,
              vehicle: this.vehicle.data
            }
          }
        }
      },
      spec: {
        visible: false,
        data: {} as EnocloudCommonDefinitions['MingjueVehicleDto']
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.refs.form.validate()
            const vehicleRes = await this.vehicle.submit()
            this.vehicle.data.owner = this.customer.data
            const customerRes = await this.customer.submit()
            this.vehicle.data.id = vehicleRes.data[0] as number
            this.customer.data.id = customerRes.data[0] as number
            await this.vehicle.get()
            await this.customer.get()
            this.emit('update:model-value', false)
            this.emit('confirm', { vehicle: this.vehicle.data, customer: this.customer.data })
          }
        }
      }
    }
  },

  async mounted() {
    this.vehicle.vehicleSpec.get()
    this.vehicle.fields = (await this.ajax('GET /enocloud/form/fields/setting', { payload: { formFieldsType: 'SV' } })).data
    this.customer.fields = (await this.ajax('GET /enocloud/form/fields/setting', { payload: { formFieldsType: 'SC' } })).data
  }
})
</script>
