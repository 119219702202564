<template>
  <div class="flex flex-col">
    <en-checkbox-group v-model="localCodes">
      <en-checkbox v-for="item of data" :label="item.spraySurface">
        {{ item.name }}
      </en-checkbox>
    </en-checkbox-group>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch, toRef } from 'vue'
import { last, indexOf } from 'lodash-es'

import { ajax } from '@enocloud/utils'

interface Props {
  name: string
  codes?: string[]
}

interface Emits {
  (e: 'update:codes', value: string[]): void
}

const props = defineProps<Props>()
const emits = defineEmits<Emits>()

const root_of_car_codes = ['RWWS', 'RWNS', 'RWHS']

const loading = ref(false)
const data = ref<EnocloudCommonDefinitions['MaintenanceDto'][]>([])

const localCodes = computed({
  get() {
    return props.codes
  },
  set(codes) {
    const _codes = codes ? [...codes] : []
    const lastcode = last(_codes)
    if (lastcode) {
      const [s, t, p, e] = lastcode.split('_')
      const rootCodes = _codes.filter((item) => item.startsWith(s) && root_of_car_codes.includes(last(item.split('_'))!))
      if (rootCodes.length > 1) {
        for (const code of root_of_car_codes.map((c) => `${s}_${t}_${p}_${c}`)) {
          if (indexOf(_codes, code) > -1 && code !== lastcode) _codes.splice(indexOf(_codes, code), 1)
        }
      }
    }

    emits('update:codes', _codes)
  }
})

const getData = async () => {
  loading.value = true
  try {
    data.value = (await ajax('GET /enocloud/common/maintenance', { payload: { spraySurface: props.name.replaceAll('-', '_') } })).data.filter(
      (item) => item.spraySurface !== 'TK_P_L_LPLQ'
    )
  } catch (e) {
  } finally {
    loading.value = false
  }
}

watch(toRef(props, 'name'), getData, { immediate: true })
</script>

<style scoped>
:deep(.el-checkbox-group) {
  display: flex;
  flex-direction: column;
}
</style>
