<template>
  <en-drawer :model-value="modelValue" title="短信发送" direction="btt" size="80%" @open="operation.open" @close="$emit('update:model-value', false)">
    <en-form label-position="left" label-width="150">
      <en-form-item label="剩余短信">{{ balance.data }}条</en-form-item>
      <en-form-item label="注意事项">
        <div class="flex flex-col">
          <span>1、自定义输入的发送内容，点击发送后需通过审核才能最终发送，审核时间大概需要5-15分钟</span>
          <span>2、短信内容不支持【】、￥、★以及特殊符号例如^_^&、☞、√、※等，以免引起短信出现乱码。</span>
          <span>3、根据国家要求禁止发送包含黄、赌、毒、色情、暴力、宗教、政治、恐吓等违法违规相关内容。</span>
          <span>4、自定义输入的短信内容长度最长500个字，如果单条短信超出70个字后，按67字/条分多条计费。</span>
        </div>
      </en-form-item>
      <en-form-item label="本次发送内容">
        <en-input v-if="table.current.data?.id" :model-value="table.current.data?.description" type="textarea" disabled></en-input>
        <en-input
          v-else
          v-model="form.data.message"
          type="textarea"
          :rows="2"
          maxlength="500"
          show-word-limit
          placeholder="请输入本次发送内容，也可选择下方其他短信模板进行发送。如果发生内容为直接选中的系统提供模板，则发送内容不可编辑"
        ></en-input>
        <en-button @click="form.message.self" type="primary" text>自定义发送</en-button>
      </en-form-item>
      <en-form-item label="本次发送客户总数">{{ form.data.details.length }}</en-form-item>
      <en-form-item label="预计发送短信条数">{{ form.data.details.length }}</en-form-item>
      <en-form-item label="选择其他短信模板">
        <div class="cursor-pointer w-full border border-neutral-100 flex flex-col divide-y divide-neutral-100">
          <div
            v-for="item of table.data"
            :class="['p-2 text-sm', { 'bg-primary text-white': table.current.data?.id === item.id }]"
            @click="table.current.change(item)"
          >
            <span class="hover:underline">{{ item.description }}</span>
          </div>
        </div>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax type="primary" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { get } from 'lodash-es'
import { EnMessage } from '@enocloud/components'

interface DataProps {
  name?: string
  cellphone?: string
}

export default factory({
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    data: {
      type: Array as PropType<unknown[]>,
      default: () => []
    },
    props: Object as PropType<DataProps>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  config: {
    children: {
      operation: {
        open() {
          this.table.current.data = null
          this.form.data.details = this.data.map((item) => {
            return {
              name: get(item, this.props?.name || 'name'),
              cellphone: get(item, this.props?.cellphone || 'cellphone'),
              params: null
            }
          })
          this.balance.get()
          this.table.get()
        }
      },
      balance: {
        ajax: {
          get: { action: 'GET /enocloud/short/message/combo/balance', data: 'number' }
        }
      },
      table: {
        ajax: {
          get: { action: 'GET /enocloud/short/message/template', data: 'array' }
        },
        children: {
          current: {
            data: null as EnocloudShortMessageDefinitions['ShortMessageTemplateDto'] | null,
            change(row: EnocloudShortMessageDefinitions['ShortMessageTemplateDto']) {
              this.table.current.data = row
            }
          }
        }
      },
      form: {
        is: 'form',
        data: {
          delay: 0,
          totalMessageCount: 0,
          succeedMessageCount: 0,
          message: '',
          details: [] as EnocloudShortMessageDefinitions['ShortMessageSendingDetailsDto'][]
        },
        ajax: {
          submit: {
            action: 'POST /enocloud/short/message/sending/order',
            params(params) {
              params.payload = this.form.data
            }
          },
          submitByTemplate: {
            action: 'POST /enocloud/short/message/sending/order/:templateId',
            params(params) {
              params.paths = [this.table.current.data?.id]
              params.data = this.form.data.details
            }
          }
        },
        children: {
          message: {
            self() {
              this.table.current.data = null
            }
          }
        }
      },
      footer: {
        confirm: {
          async click() {
            const { balance, form, table } = this
            if (balance.data < form.data.details.length) return EnMessage.warning('短信剩余数量不足,请充值')
            if (table.current.data?.id) await form.submitByTemplate()
            else await form.submit()
            this.emit('update:model-value', false)
          }
        },
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        }
      }
    }
  }
})
</script>
